import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-phone-dropdown',
  templateUrl: './phone-dropdown.component.html',
  styleUrls: ['./phone-dropdown.component.css']
})
export class PhoneDropdownComponent implements OnInit {

  @Input() phone: string;
  @Input() hover: boolean = false;
  @Input() tooltip: string = null;
  smartPhoneImage: SafeResourceUrl = '../../../assets/icons/smartphone-candidate-icon.svg';
  whatsappImage: SafeResourceUrl = '../../assets/icons/whatsapp-icon-gray.svg';
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  mobile: boolean = false;
  tablet: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const isMobile = /iPhone|iPod|Android(?!.*Tablet)|Windows Phone/i.test(navigator.userAgent);
    const isTablet = /iPad|Android.*Tablet|Kindle|Silk|SM-T/i.test(navigator.userAgent);
    this.mobile = isMobile;
    this.tablet = isTablet;
  }
}
