import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ValidatorService } from '../../services/validator.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Status } from '../../interfaces/status';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-or-edit-status',
  templateUrl: './add-or-edit-status.component.html',
  styleUrls: ['./add-or-edit-status.component.css'],
})
export class AddOrEditStatusComponent implements OnInit {

  listaStatus: Status[] = [];

  sendStatus: Status = {
    stS_COMPANY_ID: '',
    stS_NAME: '',
    stS_DESCRIPTION: '',
    stS_ID: '',
  };

  esNuevo: boolean = false;
  tabCount: number = 0;
  sending: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {
    this.tabCount++;
    if (this.tabCount === 2) {
      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;
    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.addStatus();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }

  constructor(
    private elementRef: ElementRef,
    private validatorService: ValidatorService,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: Status | string
  ) { }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]],
    descripcion: ['', [this.noSpecialCharactersValidator]],
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaStatus es una propiedad del componente que contiene el array.
    if (this.listaStatus.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  ngOnInit(): void {
    if (!(typeof this.data == 'string')) {
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.stS_NAME),
        descripcion: this.transformValue(this.data.stS_DESCRIPTION)
      })
      this.esNuevo = true;
    }
  }

  addStatus(event?: Event) {
    if (!this.esNuevo) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaStatus.find(status => status.stS_NAME === nombreValue);
        if (!existeNombre) {
          const nuevoEstado: Status = this.createStatus();
          this.listaStatus.unshift(nuevoEstado);
          this.miFormulario.reset();
        } else {
          // eliminar valor del campo nombre
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }
  }

  createStatus(): Status {
    let statusId: string | null = null;
    if (typeof this.data !== 'string' && this.data.stS_ID) {
      statusId = this.data.stS_ID;
    }
    return {
      stS_COMPANY_ID: typeof this.data === 'string' ? this.data : this.data.stS_COMPANY_ID,
      stS_NAME: this.miFormulario.get('nombre').value,
      stS_DESCRIPTION: this.miFormulario.get('descripcion').value,
      stS_ID: statusId,
    };
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.valid && !this.sending) {
      const nombreValue = this.miFormulario.get('nombre').value || '';
      const descripcionValue = this.miFormulario.get('descripcion').value || '';
      if (nombreValue.trim() !== '') {
        const status = {
          ...this.createStatus(),
          stS_DESCRIPTION: descripcionValue
        };
        if (this.listaStatus.length === 0) {
          this.sentStatus(status);
          return;
        } else {
          this.listaStatus.push(status);
        }
      }
      const estadosParaEnviar = this.listaStatus.map(status => ({
        ...status,
        stS_DESCRIPTION: status.stS_DESCRIPTION || ''
      }));
      if (estadosParaEnviar.length > 0) {
        let ultimoNumeroFase = 0;
        let responses = [];
        this.sending = true;

        const promises = estadosParaEnviar.map(async (status, index) => {
          status.stS_NUMBER = ultimoNumeroFase + 1 + index;

          try {
            const response = await this.puzzleService.createOrEditStatus(status).toPromise();
            return {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: status.stS_NAME
            };
          } catch (error) {
            const errorMessage = this.translate.instant('addStatusAlert');
            return { isSuccess: false, message: errorMessage, name: status.stS_NAME };
          }
        });

        try {
          responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error en las promesas:", error);
        }
        this.dialogRef.close(responses);
      } /* else {
        this.dialogRef.close([]);
      } */
    }
  }
  sentStatus(status: Status) {
    this.sending = true;
    this.puzzleService.createOrEditStatus(status).subscribe(
      response => {
        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        const errorMessage = this.translate.instant('addStatusAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    );
  }

  deleteStatus(index: number): void {
    this.listaStatus.splice(index, 1);
  }

  invalidField(campo: string): boolean {
    const campoValor = this.miFormulario.get(campo)?.value || '';
    if (this.listaStatus.length === 0) {
      return (this.miFormulario.get(campo)?.invalid || campoValor.trim() === '') && this.miFormulario.get(campo)?.touched;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }

}
