import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-radio-button',
  templateUrl: './custom-radio-button.component.html',
  styleUrls: ['./custom-radio-button.component.css'],
    providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomRadioButtonComponent),
      multi: true
    }
  ]
})
export class CustomRadioButtonComponent implements ControlValueAccessor {
  @Input() isDisabled: boolean = false;
  @Input() label: string = '';
  @Input() name: string;
  @Input() value: string;
  @Output() checkedChange = new EventEmitter<string>();

  private _innerValue: string;

  get innerValue(): string {
    return this._innerValue;
  }

  set innerValue(v: string) {
    if (v !== this._innerValue) {
      this._innerValue = v;
      this.onChange(v);
    }
  }
  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private onChange = (value: any) => {};

  private onTouched = () => {};

  onRadioButtonChange() {
    if (!this.isDisabled) {
      this.innerValue = this.value;
      this.checkedChange.emit(this.innerValue);
    }
  }

}
