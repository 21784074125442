<div class="flex justify-start md:py-0" [ngClass]="hover ? 'option-hover-effect' : ''">
    <a [matMenuTriggerFor]="options" [matTooltip]="tooltip ? tooltip : ('phoneHoverMessage' | translate)">
        <span class="w-max flex flex-nowrap" *ngIf="!template">{{ phone }}</span>
        <ng-container *ngIf="template">
            <ng-container *ngTemplateOutlet="template"></ng-container>
        </ng-container>
    </a>
</div>
<mat-menu #options="matMenu" class="mi-menu-personalizado">
    <div class="flex items-center justify-start px-2 py-2 w-full option">
        <a [href]="phone.length === 10 ? 'https://wa.me/52' + phone : 'https://wa.me/' + phone" target="_blank" class="flex items-center justify-around cursor-pointer">
            <img [src]="whatsappImage" alt="Link" class="h-5 w-5">
            <div class="px-2 actions-text md:flex">
                {{ 'phoneWhatsappApp' | translate }}
            </div>
        </a>
    </div>
    <div *ngIf="!mobile || tablet" class="flex items-center justify-start px-2 py-2 w-full option">
        <a [href]="phone.length === 10 ? 'https://web.whatsapp.com/send?phone=52' + phone : 'https://web.whatsapp.com/send?phone=' + phone" target="_blank" class="flex items-center justify-around cursor-pointer">
            <img [src]="whatsappImage" alt="Link" class="h-5 w-5">
            <div class="px-2 actions-text md:flex">
                {{ 'phoneWhatsappWeb' | translate }}
            </div>
        </a>
    </div>
    <div class="flex items-center justify-start px-2 py-2 w-full option">
        <a [href]="'tel:+' + phone" target="_blank" class="flex items-center justify-around cursor-pointer">
            <img [src]="smartPhoneImage" alt="Link" class="w-5 h-5">
            <div class="px-2 actions-text md:flex">
                {{ 'phoneCall' | translate }}
            </div>
        </a>
    </div>
</mat-menu>