import { Component, OnInit } from '@angular/core';
import { PuzzleService } from '../services/puzzle.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ResponseDialogComponent } from '../dialogs/response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from '../services/local.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.css']
})
export class RecoverComponent implements OnInit {

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private router: Router, private puzzleService: PuzzleService, private translate: TranslateService, private localService: LocalService) { }

  obj = { email: '' }

  enableButton: boolean = false;
  currentLang: string;
  recoverTitlePassword: string;
  recoverEmailPassword: string;
  recoverButtonPassword: string;
  recoverBackLoginPassword: string;


  ngOnInit(): void {
    this.initializeLanguage();

    this.translate.get('recoverPasswordTitle').subscribe((res: string) => {
      this.recoverTitlePassword = res;
    });

    this.translate.get('recoverPasswordEmail').subscribe((res: string) => {
      this.recoverEmailPassword = res;
    });

    this.translate.get('recoverPasswordSend').subscribe((res: string) => {
      this.recoverButtonPassword = res;
    });

    this.translate.get('recoverPasswordBack').subscribe((res: string) => {
      this.recoverBackLoginPassword = res;
    });

  }


  initializeLanguage() {
    if (this.localService.getJsonValue('lang')) {
      this.translate.use(this.localService.getJsonValue('lang'));
      this.currentLang = this.localService.getJsonValue('lang');
    } else {
      const browserLang = this.translate.getBrowserLang();
      let appLang = this.mapBrowserLangToAppLang(browserLang);
      this.translate.use(appLang);
      this.currentLang = appLang;
      this.localService.setJsonValue('lang', appLang);
    }
  }

  changeLanguage(lang: string): void {
    const newLang = lang === 'es' ? 'esp' : 'eng';
    this.translate.use(newLang);
    this.currentLang = newLang;
    this.updateTexts();
    this.localService.setJsonValue('lang', newLang);
  }

  updateTexts(): void {
    this.translate.get('recoverPasswordTitle').subscribe((res: string) => {
      this.recoverTitlePassword = res;
    });

    this.translate.get('recoverPasswordEmail').subscribe((res: string) => {
      this.recoverEmailPassword = res;
    });

    this.translate.get('recoverPasswordSend').subscribe((res: string) => {
      this.recoverButtonPassword = res;
    });

    this.translate.get('recoverPasswordBack').subscribe((res: string) => {
      this.recoverBackLoginPassword = res;
    });
  }

  mapBrowserLangToAppLang(browserLang: string): string {
    const langMap = {
      'en': 'eng',
      'es': 'esp'
    };
    return langMap[browserLang] || 'eng';
  }


  Recuperacion() {

    if (this.obj.email == '') {

      this.openResponseDialog('Error', this.translate.instant('recoverNoEmail'));

    } else {

      this.puzzleService.recoverPassword(this.obj).subscribe(resp => {

        /*         console.log(JSON.stringify(resp));
         */
        if (resp.isSuccess) {
          const confirmMessage = this.translate.instant('recoverAlertConfirm');
          this.openResponseDialog('Exito', confirmMessage);
          this.obj.email = '';

        } else {
          const errorMessage = this.translate.instant('recoverEmaldontExist');
          this.openResponseDialog('Error', errorMessage);

        }

      });
    }
  }

  openResponseDialog(response: string, message?: string) {

    let data: string[] = [];
    this.enableButton = true;

    data.push(response);
    data.push(message);

    const dialogRef = this.dialog.open(ResponseDialogComponent, { width: '500px', data: data });

    dialogRef.afterClosed().subscribe(result => this.enableButton = false);


  }

}
