import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanySubdomain } from 'src/app/interfaces/CompanySubdomain';
import { APIResponse } from 'src/app/interfaces/response';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-add-subdomain',
  templateUrl: './add-subdomain.component.html',
  styleUrls: ['./add-subdomain.component.css']
})

export class AddSubdomainComponent implements OnInit {
  subdomainForm: FormGroup;
  subdomainInvalid: boolean = false;
  sending: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private puzzleService: PuzzleService,
    public dialogRef: MatDialogRef<AddSubdomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.subdomainForm = this.formBuilder.group({
      subdomain: ['', [Validators.required, Validators.pattern('^[a-z0-9-]+$')]]
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  async submitSubdomain(): Promise<void> {
    if (this.subdomainForm.valid) {
      this.sending = true;
      const subdomain = this.subdomainForm.value.subdomain;
      const response = await this.createSubdomain(subdomain);
      this.sending = false;

      if (response.isSuccess) {
        this.dialogRef.close({ isSuccess: true, status: 'Exito', subdomain });
      } else {
        this.dialogRef.close({ isSuccess: false, status: 'Error', message: response.message });
        this.subdomainInvalid = true;
      }
    } else {
      this.subdomainInvalid = true;
    }
  }

  validateSubdomain(): void {
    const subdomainControl = this.subdomainForm.get('subdomain');
    const sanitizedValue = subdomainControl.value.toLowerCase().replace(/[^a-z0-9-]/g, '');
    subdomainControl.setValue(sanitizedValue, { emitEvent: false });
  }

  handlePaste(event: ClipboardEvent): void {
    event.preventDefault();
    const clipboardData = event.clipboardData || window['clipboardData'];
    const pastedData = clipboardData.getData('text').toLowerCase().replace(/[^a-z0-9-]/g, '');
    const subdomainControl = this.subdomainForm.get('subdomain');
    subdomainControl.setValue(pastedData, { emitEvent: true });
  }

  private async createSubdomain(subdomain: string): Promise<APIResponse> {
    const companyId = this.data.otherData.comP_ID;
    const payload: CompanySubdomain = {
      COMP_ID: companyId,
      COMP_SUBDOMAIN: subdomain
    };

    try {
      const response = await this.puzzleService.createSubdomain(payload).toPromise();
      return response;
    } catch (error) {
      console.error('Error creating subdomain:', error);
      return { isSuccess: false, message: 'Error creating subdomain', messageCode: '', errorInformation: '', dataType: '', data: null };
    }
  }
}
