<div class="flex flex-col items-center w-full max-w-md">

  <!-- Botón Cerrar (X) en la Parte Superior Derecha -->
  <div class="self-end cursor-pointer">
    <img [src]="closeIcon" alt="deleteIcon" (click)="closeDialog()">
  </div>

  <!-- Título "Iniciar sesión" -->
  <div class="login-title mb-10">{{"loginTitle" | translate}}</div>

  <!-- Input Usuario -->
  <div class="w-full mb-6">
    <div class="login-input-label mb-1">{{"loginEmail" | translate}}</div>
    <app-custom-input id="loginEmail" [(ngModel)]="user" [placeholder]="'loginEmailPlaceholder' | translate" name="usuario"
      [type]="'text'" width="100%" [maxlength]="100" [autocapitalize]="'none'" [autocomplete]="'email'"></app-custom-input>
  </div>

  <!-- Input Contraseña -->
  <div class="w-full mb-6 relative">
    <div class="login-input-label mb-1">{{ "loginPLaceHolderPassword" | translate }}</div>
    <div class="flex items-center">
      <app-custom-input id="passInputLoginDialog" [(ngModel)]="password" placeholder="•••••••••" name="password" class="w-full"
        [type]="hide ? 'password' : 'text'" (keydown.enter)="login()" [maxlength]="50">
      </app-custom-input>
      <!-- Ícono de visibilidad, colocado de manera absoluta -->
      <mat-icon class="cursor-pointer icon-password" (click)="hide = !hide"
        style="position: absolute; right: 10px; top: 63%; transform: translateY(-50%);">
        {{ hide ? 'visibility_off' : 'visibility' }}
      </mat-icon>
    </div>
  </div>



  <!-- Botón Iniciar Sesión -->
  <div class="pb-8 w-full">
    <app-custom-primary-button id="loginTitle" class="w-full" (click)="login()" color="primary" size="auto">
      {{"loginTitle" | translate}}
    </app-custom-primary-button>
  </div>


  <div class="login-text-miss-password pb-2">
    {{"loginForgotPassword" | translate}}
  </div>

  <a id="loginRecoverPassword" href="recover" class="login-recover-password">{{"loginRecoverPassword" | translate}}</a>

  <div *ngIf="errorMessage" class="error-modal">
    <div class="error-content">
      <img [src]="dangerousIcon" alt="Icono de error" class="error-icon ml-3">
      <div class="flex flex-col items-start">
        <div class="additional-text pl-4 pt-2 pb-1">Error</div>
        <div class="error-message pl-4 pb-2">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <mat-icon id="closeErrorModalLogin" class="close-button cursor-pointer mr-2 mb-4" (click)="closeErrorModal()">close</mat-icon>
  </div>

  <!--   <div *ngIf="successMessage" class="error-modal bg-green-500 border-green-700 ...">
    <div class="success-message">
      {{ successMessage }}
    </div>
    <div class="close-button" (click)="closeModal()">X</div>
  </div> -->


</div>
