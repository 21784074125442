import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomDropdownComponent),
      multi: true
    }
  ]
})
export class CustomDropdownComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() displayProperty: string = '';
  @Output() selectionChanged = new EventEmitter<any>();
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() mobileResponsive: boolean = false;
  @Input() control: FormControl;
  @Input() translateLabels: boolean = false;
  @Input() formatFunction: (item: any) => string;
  @Input() maxVisibleOptions: number = 5;
  @Input() tabindexnumber: any;
  selectedItem: any = {};
  dropdownOpen: boolean = false;
  hoveredItem: any = null;
  formattedSelectedItem: string;
  selectedIndex: number = -1;
  maxHeight: number;

  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) { }


  ngOnInit() {
    this.maxHeight = this.maxVisibleOptions * 40;
    if (this.control) {
      this.control.valueChanges.subscribe(value => {
        this.selectedItem = value;
        this.updateFormattedSelectedItem();
      });
    }
  }



  onChange: any = () => { };
  onTouched: any = () => { };

  writeValue(obj: any): void {
    this.selectedItem = obj;
    this.updateFormattedSelectedItem();
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateFormattedSelectedItem() {
    if (this.formatFunction && this.selectedItem) {
      this.formattedSelectedItem = this.formatFunction(this.selectedItem);
    } else {
      this.formattedSelectedItem = this.selectedItem ? this.selectedItem[this.displayProperty] : '';
    }
    if (this.formattedSelectedItem && this.translateLabels) {
      this.formattedSelectedItem = this.translate.instant(this.formattedSelectedItem);
    }
  }

  onFocus() {
    this.dropdownOpen = true;
  }

  onBlur() {
    this.dropdownOpen = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onSelectKeyPress(event: KeyboardEvent, item: any) {
    if (event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.onSelect(item);
    }
  }

  onSelect(item: any) {
    this.selectedIndex = -1;
    this.selectedItem = item;
    this.onChange(item);
    this.onTouched();
    this.control?.setValue(item);
    if (this.formatFunction) {
      this.formattedSelectedItem = this.formatFunction(item);
    } else {
      this.formattedSelectedItem = item[this.displayProperty];
    }
    if (this.formattedSelectedItem && this.translateLabels) {
      this.formattedSelectedItem = this.translate.instant(this.formattedSelectedItem);
    }
    this.dropdownOpen = false;
    this.selectionChanged.emit(item);
  }

  resetDropdown() {
    this.selectedItem = null;
    this.control?.setValue(null);
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      this.navigate(event.key);
      event.preventDefault();
    } else if (event.key === 'Enter' && this.selectedIndex >= 0) {
      this.selectItem();
    } else if (event.key === 'Space') {
      this.toggleDropdown();
      event.preventDefault();
    }
  }

  navigate(direction: string) {
    if (direction === 'ArrowDown') {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    } else if (direction === 'ArrowUp') {
      this.selectedIndex = (this.selectedIndex - 1 + this.items.length) % this.items.length;
    }
  }

  selectItem() {
    if (this.selectedIndex >= 0) {
      this.onSelect(this.items[this.selectedIndex]);
    }
  }

}

