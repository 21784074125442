<div [innerHTML]="data[1]" class=" text-center text-lg font-medium mb-2" style="color: #06589F;">

</div>

<div [innerHTML]="data[2]" class=" text-center text-lg font-medium" style="color: #06589F;">
  <!-- {{data[2]}} -->
</div>

<div class="flex justify-center py-4">
  <ng-lottie *ngIf="data[0] == 'Exito'" width="90px" height="90px" [options]="options"
    (animationCreated)="animationCreated($event)"></ng-lottie>
  <ng-lottie *ngIf="data[0] == 'Error'" width="90px" height="90px" [options]="options2"
    (animationCreated)="animationCreated($event)"></ng-lottie>
  <img *ngIf="data[0] == 'loading'" src="/assets/animations/haibu-loading.gif" style="height: 120px; width: 120px;">
</div>

<div class="flex justify-center ">
  <app-custom-primary-button id="responseSmallDialogAccept" size="small" color="primary" (click)="cerrar()">
    {{"responseAnchorDialogAccept" | translate}}
  </app-custom-primary-button>
</div>
