export const constants  = {
    candidateDocumentId:"4966fcff-56dd-4b2a-bd5a-df0024a6c429",
    candidateEvaluationId:"08907a58-094a-40ea-ac91-23cdcbc7a2d4",
    applicationVersionCheckDelayMs:10000,
    recapcha:"6LdmHjwdAAAAAJMVmAWyzAyWr5d5Jq9B11jNJ4qZ",
    superadmin:"93E0D8B2-E562-4409-9A52-2A1A481A9F7D",
    admin:"D5A355E2-B288-4F4F-A21E-386C86CC1A0A",
    allowedExt: ['jpg', 'jpeg', 'gif', 'png', 'webp', 'doc', 'docx', 'pdf', 'csv', 'xls', 'xlsx'],
    maxFileSize: 15728640 // 15MB
    // maxFileSize: 5242880 // 5MB
};
