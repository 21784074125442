<div *ngIf="title" class="py-2 px-5 m-0 title-dialog" mat-dialog-title>
  <div class="flex justify-between items-center pb-0">
    <div class="title-doc-text">{{ title | translate }}</div>
    <button id="closeBtnDocuView" mat-icon-button mat-dialog-close>
      <mat-icon class="close-x-primary" aria-label="Cerrar">close</mat-icon>
    </button>
  </div>
</div>
<div class="container flex items-center justify-center ">

  <!-- <iframe *ngIf="!imgsource && !pdfUrl" [src]="pdfsource" frameborder="0" width="100%" height="100%">
    {{ 'documentViewerTitle' | translate }}
  </iframe> -->

  <ng2-pdfjs-viewer *ngIf="!imgsource && pdfUrl" [pdfSrc]="pdfUrl" class="pdf-iframe"></ng2-pdfjs-viewer>

  <div *ngIf="imgsource" class="image-cont w-full max-h-full">
    <img [src]="imgsource" class="max-w-full image-doc mx-auto" />
  </div>
</div>