<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
        <mat-icon class="close-x-primary">close</mat-icon>
    </button>
</div>

<div class="pb-2 text-center title-text-modal">
    {{ 'discardMailDialogTitle' | translate }}
</div>

<form [formGroup]="miFormulario">
    <div class="md:px-6 px-0 pt-2">
        <div tourAnchor="discard.mail.message" class="py-2">
            <label class="pr-6 pt-1 green-subtitle" for="descripcion">{{ 'discardMailMessage' | translate }}</label>
            <textarea id="discardMailMessage" class="h-80 p-2 w-full mt-1 borde" type="text" name="descripcion" rows="4"
                formControlName="descripcion" maxlength="4000"
                oninput='this.value = this.value.trimStart();'></textarea>

            <div *ngIf="miFormulario.get('descripcion').invalid && (miFormulario.get('descripcion').dirty || miFormulario.get('descripcion').touched)"
                class="text-red-500 text-xs mt-1 flex">
                <span *ngIf="miFormulario.get('descripcion').errors['required']">
                    {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('discardMailMessage' | translate) } }}
                </span>
            </div>
        </div>
        <div tourAnchor="discard.mail.example" class="mb-2">
            <div class="text-xs text-primary cursor-pointer" (click)="toggleDescripcion()">
                {{ mostrarDescripcion ? ('hideMailExample' | translate) : ('showMailExample' | translate )}}
            </div>
            <div *ngIf="mostrarDescripcion" class="text-xs text-gray-700 py-2">
                <div>
                    <span class="font-medium">{{ ('candidateDefaultName' | translate) }}.</span>&nbsp;
                    <div [innerHTML]="miFormulario.get('descripcion').value" class="whitespace-pre-wrap break-words"></div>
                </div>
                <br>
                <div [innerHTML]="'discardMailDefault' | translate: { subdomain: subdomain + '.haibuhr.com', compName: compName }"></div>
            </div>
        </div>
        <div class="text-xs text-gray-500">
            {{ 'discardMailMessageNotice' | translate }}
        </div>
    </div>

    <div class="md:px-6 px-0 flex justify-center pt-4">
        <app-custom-primary-button tourAnchor="discard.mail.save" id="saveDiscardMail"
            type="submit" size="filter-size" color="primary" class="text-center" [textPadding]="'15px'"
            [textAlign]="'inherit'" (click)="createOrUpdateDiscardMessage()">
            <div class="w-full flex justify-center gap-2">
                <mat-spinner *ngIf="sending" diameter="20" color="primary"></mat-spinner>
                <span *ngIf="!sending" class="btn-label">{{ 'saveDiscardMessage' | translate }}</span>
            </div>
        </app-custom-primary-button>
    </div>
</form>