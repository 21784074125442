<div *ngIf="!disableClose" class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="cerrar(false)" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="pb-3 text-center title-text-modal">
  {{data[0]}}
</div>


<div class="py-4 text-center text-gray-600 font-size"> {{data[1]}} </div>
<div class="flex justify-center mt-4">
  <div class="w-full md:w-1/2">
    <app-custom-primary-button id="confirmDialogBtn" id="btnNo" size="small" color="primary" class="w-full"
      (click)="cerrar(true)">
      {{ 'confirmDialog' | translate }}
    </app-custom-primary-button>
  </div>
</div>
