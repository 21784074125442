import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';

@Component({
  selector: 'app-confirm-dialog-icon',
  templateUrl: './confirm-dialog-icon.component.html',
  styleUrls: ['./confirm-dialog-icon.component.css']
})
export class ConfirmDialogIconComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/animations/checkmark.json',
  };

  options2: AnimationOptions = {
    path: '/assets/animations/error.json',
  };

  options3: AnimationOptions = {
    path: '/assets/animations/loading.json',
  };

  options4: AnimationOptions = {
    path: '/assets/animations/warning.json',
  };

  constructor( private dialogRef: MatDialogRef<ResponseSmallDialogComponent>, @Inject(MAT_DIALOG_DATA) public data:string[], @Inject(MAT_DIALOG_DATA) public response:string ) { }

  ngOnInit(): void {
  }

  cerrar( option: boolean ){
    if (option) {
      this.dialogRef.close(true);
    }
    else{
      this.dialogRef.close();
    } 
  }

}
