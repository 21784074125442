import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ValidatorService } from '../../services/validator.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Job } from '../../interfaces/job';
import { TranslateService } from '@ngx-translate/core';
import { DocuViewerComponent } from '../docu-viewer/docu-viewer.component';
import { ResponseSmallDialogComponent } from '../response-small-dialog/response-small-dialog.component';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { JobDocument } from 'src/app/interfaces/job-document';


@Component({
  selector: 'app-add-or-edit-job',
  templateUrl: './add-or-edit-job.component.html',
  styleUrls: ['./add-or-edit-job.component.css']
})
export class AddOrEditJobComponent implements OnInit {
  editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
    ]
  };

  sendJob: Job = {
    joB_COMPANY_ID: '',
    joB_NAME: '',
    joB_DESCRIPTION: '',
    joB_ID: '',
  };

  job: Job = {
    joB_COMPANY_ID: '',
    joB_NAME: '',
    joB_DESCRIPTION: '',
    joB_ID: '',
  };

  charCount: number = 0;
  maxLength: number = 5000;

  esNuevo: boolean = false;
  listaPuestos: Job[] = [];
  tabCount: number = 0;

  openDocumentOpen: boolean = false;
  fileloader: HTMLElement;
  selectedFile: File;
  selectingFile: boolean = false;
  fileForDelete: JobDocument

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]],
    descripcion: ['']
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaPuestos es una propiedad del componente que contiene el array.
    if (this.listaPuestos.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  deleteIcon: SafeResourceUrl = '../../../assets/icons/delete-orange-icon.svg';
  fileIcon: SafeResourceUrl = '../../../assets/icons/file-icon.svg';

  sending: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {
    this.tabCount++;
    if (this.tabCount === 2) {
      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }
  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;
    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.addJob();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }

  constructor(
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Job | string
  ) { }

  ngOnInit(): void {
    if (!(typeof this.data == 'string')) {
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.joB_NAME),
        descripcion: this.data.joB_DESCRIPTION
      })
      this.esNuevo = true;
      this.job = this.data;
    }
  }

  addJob(event?: Event) {
    if (!this.esNuevo) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaPuestos.find(job => job.joB_NAME === nombreValue);
        if (!existeNombre) {
          const nuevaSucursal: Job = this.createJob();
          this.listaPuestos.unshift(nuevaSucursal);
          this.miFormulario.reset();
        } else {
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }
  }

  createJob(): Job {
    let jobID: string | null = null;
    if (typeof this.data !== 'string' && this.data.joB_ID) {
      jobID = this.data.joB_ID;
    }
    return {
      joB_COMPANY_ID: typeof this.data === 'string' ? this.data : this.data.joB_COMPANY_ID,
      joB_NAME: this.miFormulario.get('nombre').value,
      joB_DESCRIPTION: this.miFormulario.get('descripcion').value,
      joB_ID: jobID,
    };
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.valid) {
      const nombreValue = this.miFormulario.get('nombre').value || '';
      const descripcionValue = this.miFormulario.get('descripcion').value || '';
      if (nombreValue.trim() !== '') {
        const job = {
          ...this.createJob(),
          joB_DESCRIPTION: descripcionValue
        };
        if (this.listaPuestos.length === 0) {
          this.sentJob(job);
          return;
        } else {
          this.listaPuestos.push(job);
        }
      }

      const puestosParaEnviar = this.listaPuestos.map(puesto => ({
        ...puesto,
        esNuevo: !puesto.joB_ID,
        joB_DESCRIPTION: puesto.joB_DESCRIPTION || ''
      }));

      if (puestosParaEnviar.length > 0) {
        let ultimoNumeroFase = 0;
        let responses = [];
        this.sending = true;

        const promises = puestosParaEnviar.map(async (job, index) => {
          job.joB_NUMBER = ultimoNumeroFase + 1 + index;

          try {
            const response = await this.puzzleService.createOrEditJob(job).toPromise();
            return {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: job.joB_NAME
            };
          } catch (error) {
            const errorMessage = this.translate.instant('addDepartamentAlert');
            return { isSuccess: false, message: errorMessage, name: job.joB_NAME };
          }
        });

        try {
          responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error en las promesas:", error);
        }
        this.dialogRef.close(responses);
      } else {
        this.dialogRef.close([]);
      }
    }
  }

  async sentJob(job) {
    if (!this.openDocumentOpen) {
      const translatedMessage = this.translate.instant('jobUpdateJobWait');
      this.sending = true;
      if (this.selectedFile) {
        const jobDoc: JobDocument = {
          JobId: job.joB_ID,
          DocumentTypeId: 2,
          PersonalDocumentationTypeId: 2,
          DocumentName: "Perfil de puesto",
          fileWrapper: this.selectedFile
        };
        const formData = new FormData();
        formData.append('JobId', jobDoc.JobId);
        formData.append('DocumentTypeId', jobDoc.DocumentTypeId.toString());
        formData.append('PersonalDocumentationTypeId', jobDoc.PersonalDocumentationTypeId.toString());
        formData.append('DocumentName', jobDoc.DocumentName);
        formData.append('fileWrapper.file', jobDoc.fileWrapper, jobDoc.fileWrapper.name);
        await this.puzzleService.createOrEditJobDocument(formData).toPromise();
      }
      this.puzzleService.createOrEditJob(job).subscribe(
        response => {
          const translatedMessage = this.translate.instant(response.message);
          this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
        },
        error => {
          const errorMessage = this.translate.instant('addJobAlert');
          this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
        }
      );
    }
  }

  deleteJob(index: number): void {
    this.listaPuestos.splice(index, 1);
  }

  invalidField(campo: string): boolean {
    const campoValor = this.miFormulario.get(campo)?.value || '';
    if (this.listaPuestos.length === 0) {
      return (this.miFormulario.get(campo)?.invalid || campoValor.trim() === '') && this.miFormulario.get(campo)?.touched;
    }
    return false;
  }

  openDocument(element, withTitle) {
    if (this.sending) {
      return;
    }
    const allData = {
      data: element,
      header: 'Diseñar perfil de puesto'
    }
    if (withTitle) {
      allData['title'] = 'createProfileJobForUpload';
    }
    this.openDocumentOpen = true;
    let dialog = this.dialog.open(DocuViewerComponent, {
      panelClass: 'custom-dialog-cv',
      maxWidth: 'none',
      data: allData,
    });

    dialog.afterClosed().subscribe((result) => {
      this.openDocumentOpen = false;
    });
  }

  fileSelectClick() {
    if (this.sending) {
      return;
    }
    if (this.fileloader == null) {
      this.fileloader = document.getElementById('fileSelect');
    }
    this.fileloader.click();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.selectedFile = fileInput.target.files[0];
    } else {
      this.selectedFile = null;
    }
  }

  deletedoc(job: Job | null) {
    event.stopPropagation();
    const data = [];
    const deleteTitle = this.translate.instant('catalogueJobProfileDelete');
    const deleteMessage = this.translate.instant('catalogueJobProfileDeleteMessage');
    data.push(deleteTitle);
    data.push(deleteMessage);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      // height: '250px',
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (!job) {
          this.selectedFile = null;
          const successMessage = this.translate.instant('jobDeleteJobProfile');
          this.openResponseDialog(1, successMessage)
        } else {
          this.deleteJobDoc(job);
        }
      }
    });
    this.selectedFile = null;
    this.fileloader = null;
  }

  deleteJobDoc(job) {
    const jobDoc: JobDocument = {
      JDocId: job.jdoC_ID,
      DocumentTypeId: 2,
      PersonalDocumentationTypeId: 2,
      DocumentName: "Perfil de puesto",
      fileWrapper: new File([job.jdoC_DOCUMENT_ROUTE], job.jdoC_DOCUMENT_NAME + '.pdf', { type: 'application/pdf' }),
      jdoC_ACTIVE: false
    };
    const formData = new FormData();
    formData.append('JDocId', jobDoc.JDocId);
    formData.append('DocumentTypeId', jobDoc.DocumentTypeId.toString());
    formData.append('PersonalDocumentationTypeId', jobDoc.PersonalDocumentationTypeId.toString());
    formData.append('DocumentName', jobDoc.DocumentName);
    formData.append('fileWrapper.file', jobDoc.fileWrapper, jobDoc.fileWrapper.name);
    formData.append('jdoC_ACTIVE', jobDoc.jdoC_ACTIVE.toString());

    const translatedMessage = this.translate.instant('jobDeleteJobProfileWait');
    let loading = this.openResultDialog(2, translatedMessage);
    this.puzzleService.createOrEditJobDocument(formData).subscribe(
      response => {
        loading.close();
        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        loading.close();
        const errorMessage = this.translate.instant('addJobAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    );
  }

  openResponseDialog(mode: number, message?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(message);
        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 1: {
        data.push('Exito');
        data.push(message);

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 2: {
        data.push('loading');
        data.push(message);


        return this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
    }
  }

  openResultDialog(mode: number, message?: string, content?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0:
        {
          data.push('Error');
          data.push(message);
          if (content) data.push(content);
          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 1:
        {
          data.push('Exito');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
      case 2:
        {
          data.push('loading');
          data.push(message);
          if (content) data.push(content);

          return this.dialog.open(ResponseSmallDialogComponent, {
            width: '500px',
            data: data,
          });
        }
        break;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }
}
