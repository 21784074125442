import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocalService } from 'src/app/services/local.service';
import { MatSelectChange } from '@angular/material/select';
@Component({
  selector: 'app-add-or-edit-client',
  templateUrl: './add-or-edit-client.component.html',
  styleUrls: ['./add-or-edit-client.component.css']
})

export class AddOrEditClientComponent implements OnInit {
  editando: boolean = false;
  sending: boolean = false;
  showErrorMessage: boolean = false;
  showErrorMessage1: boolean = false;
  clientsFiltrados = [];
  selectedClients = [];
  clients = [];
  pageSize = 100;
  pageIndex = 0;
  id: string;
  loadingUsers: boolean = false;
  loadingUsersExist: boolean = false;
  @ViewChild('select1') select1: ElementRef;

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.editando) return;

    event.preventDefault();
    this.submitForm();
  }

  constructor(
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    private localService: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.id = data.data;
   }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [Validators.required, this.noSpecialCharactersValidator]]
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  ngOnInit(): void {
    let tokencito = this.localService.getJsonValue('token');

    if (typeof this.data.data === 'string') {
      this.editando = false;
    } else {
      this.editando = true;
      this.miFormulario.get('nombre').setValue(this.transformValue(this.data.data.comP_NAME))
    }

    if (tokencito.data.companyId != null) {
      this.id = tokencito.data.companyId;
      this.getUsers();
    } else if (this.id != null) {
      this.getUsers();
    }
  }

  getUsers(): void {
    if (this.clients.length === 0) {
      this.loadingUsers = true;
      this.loadingUsersExist = this.editando;
      this.puzzleService.getAllUsers(this.pageSize, this.pageIndex, null, 'Activos', this.id, null, true).subscribe(clients => {

        this.clients = clients.map(user => ({
          id: user.id.toLowerCase(),
          nombre: user.anU_FULL_NAME,
          email: user.email
        }));

        if (this.editando) {
          this.puzzleService.getUsersByParentAndMiniCompany(this.id, this.data.data.comP_ID, this.pageSize, this.pageIndex + 1).subscribe(res => {
            const users = res.map(user => ({
              id: user.userId.toLowerCase(),
              nombre: user.anU_NAME + (user.anU_LAST_NAME ? ' ' + user.anU_LAST_NAME : '') + (user.anU_SECOND_LAST_NAME ? ' ' + user.anU_SECOND_LAST_NAME : ''),
              email: user.email
            }));
            this.selectedClients = users

            // quitar si ya se selecciono
            this.clientsFiltrados = this.clients.filter(usuario =>
              !this.selectedClients.find(selectedUser => selectedUser.id === usuario.id)
            );

            this.loadingUsersExist = false;
          })
        } else {
          // quitar si ya se selecciono
          this.clientsFiltrados = this.clients.filter(usuario =>
            !this.selectedClients.find(selectedUser => selectedUser.id === usuario.id)
          );
        }

        this.loadingUsers = false;
      }, error => {
      });
    }
  }

  onClientSelected(event: MatSelectChange): void {
    const client = event.value;
    this.showErrorMessage1 = false;
    if (!this.selectedClients.some(u => u.id === client.id)) {
      this.selectedClients.push(client);
      this.clientsFiltrados = this.clientsFiltrados.filter(u => u.id != client.id)
    }
  }

  removeClient(userObject: any) {
    this.selectedClients = this.selectedClients.filter(user => user.id !== userObject.id);

    this.clientsFiltrados = this.clients.filter(usuario =>
      !this.selectedClients.some(selectedClient => selectedClient.id === usuario.id)
    );
  }

  onFocus(input: number) {
    switch (input) {
      case 0:
        this.select1['_elementRef'].nativeElement.classList.add('focused');
        break;
      default:
        break;
    }
  }

  onBlur(input: number) {
    switch (input) {
      case 0:
        this.select1['_elementRef'].nativeElement.classList.remove('focused');
        break;
      default:
        break;
    }
  }

  get modalTitle(): string {
    return !this.editando ? this.translate.instant('addClientModalTitle') : this.translate.instant('addEditClientModalTitle');
  }

  async submitForm() {
    if (this.sending || this.loadingUsers || this.loadingUsersExist) {
      return
    }
    this.miFormulario.markAllAsTouched();

    let inputValue = this.miFormulario.get('nombre').value || '';
    inputValue = inputValue?.trim()

    if (inputValue == '' || inputValue == null) {
      this.showErrorMessage = true;
    } else {
      this.showErrorMessage = false;
    }

    if (this.selectedClients.length <= 0) {
      this.showErrorMessage1 = true;
    } else {
      this.showErrorMessage1 = false;
    }

    if (this.showErrorMessage || this.showErrorMessage1) {
      return
    }

    let company = {}

    if (!this.editando) {
      company = {
        AGENCY_ID: this.data.data,
        COMP_NAME: inputValue,
        UserIds: this.selectedClients.map(client => client.id)
      }

      try {
        this.sending = true
        await this.puzzleService.createMiniCompany(company).subscribe(async (response) => {
          this.dialogRef.close(
            {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: inputValue
            }
          );
        })
      } catch (error) {
        const errorMessage = this.translate.instant('addDepartamentAlert');
        this.dialogRef.close(
          { isSuccess: false, message: errorMessage, name: inputValue }
        );
      }
    } else {
      company = {
        COMP_ID: this.data?.data?.comP_ID,
        COMP_NAME: inputValue,
        UserIds: this.selectedClients.map(client => client.id)
      }

      try {
        this.sending = true
        await this.puzzleService.editMiniCompany(company).subscribe(async (response) => {
          this.dialogRef.close(
            {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: inputValue
            }
          );
        })
      } catch (error) {
        const errorMessage = this.translate.instant('addDepartamentAlert');
        this.dialogRef.close(
          { isSuccess: false, message: errorMessage, name: inputValue }
        );
      }
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }

}

