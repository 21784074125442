<div class="flex justify-end pt-4 px-10">
  <select (change)="changeLanguage($event.target.value)" [value]="currentLang"
    class="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
    <option value="es" [selected]="currentLang === 'esp'">Español</option>
    <option value="en" [selected]="currentLang === 'eng'">English</option>
  </select>
</div>

<div class="flex flex-col items-center justify-center h-full pt-20 md:px-0 px-10">
  <div class="w-376 h-504 shadow-lg rounded-sm bg-white flex flex-col items-center py-10 px-6">

    <!-- Título "Recuperar contraseña" -->
    <div class="login-title mb-10 w-full text-center"[innerHTML]="'recoverPasswordTitle' | translate"></div>

    <div class="login-text-miss-password pb-4" [innerHTML]="'recoverPasswordSubtitle' | translate"></div>

    <!-- Input Correo Electrónico -->
    <div class="w-full mb-6">
      <div class="login-input-label mb-1">{{ "recoverPasswordEmail" | translate}}</div>
      <app-custom-input [(ngModel)]="obj.email"  [placeholder]="'loginEmailPlaceholder' | translate" name="usuario" [type]="'text'"
        width="100%"  (keydown.enter)="enableButton = true; Recuperacion()"></app-custom-input>
    </div>

    <!-- Botón para Recuperar Contraseña -->
    <div class="w-full pb-8">
      <app-custom-primary-button (click)="enableButton = true; Recuperacion()" class="w-full" color="primary"
        size="auto">
        {{'recoverPasswordSend' | translate }}
      </app-custom-primary-button>
    </div>

    <div class="login-text-miss-password pb-2"[innerHTML]="'recoverPasswordIncorrectOption' | translate"></div>

    <!-- Enlace para Volver al Inicio de Sesión -->
    <a href="login" class="login-recover-password">{{ "recoverPasswordBack" | translate}}</a>
  </div>
</div>
