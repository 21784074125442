import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Token } from 'src/app/interfaces/token';
import { UserLogin } from 'src/app/interfaces/user-login';
import { LoginComponent } from 'src/app/login/login.component';
import { constants } from 'src/app/services/constants';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent {

  user: string = '';
  password: string = '';
  hide: boolean = true;
  errorMessage: string = '';
  token: Token;
  closeIcon: SafeResourceUrl = '../../../assets/icons/close-icon.svg'
  dangerousIcon: SafeResourceUrl = '../../../assets/icons/emergency-icon.svg'

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private router: Router,
    private translate: TranslateService
  ) {}

  login() {
    if (!this.password || !this.user) {
      this.errorMessage = this.translate.instant('loginEmptyFields');
      return;
    }

    let userLogin: UserLogin = {
      UserName: this.user,
      Password: this.password,
      RefreshToken: '',
      AccessToken: ''
    };

    this.puzzleService.login(userLogin).subscribe(token => {
      if (token.isSuccess) {
        this.localService.setJsonValue('token', token);

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let processid = urlParams.get('processid');

        this.token = this.localService.getJsonValue('token');

        if (this.token.data.roleId == constants.superadmin) {
          this.router.navigate(['home/clientlist']);
        } else {
          if (processid != null && processid != '') {
            this.router.navigate(['home/redirect'], { queryParams: { processid: processid } });
          } else {
            this.router.navigate(['home/dashboard']);
          }
        }

        this.dialogRef.close();
      } else {
        // Manejar errores específicos de forma traducida
        if (token.message === 'User account does not exist') {
          this.errorMessage = this.translate.instant('loginUserNotExist'); // Traducción de 'User account does not exist'
        } else if (token.message === 'Incorrect user credentials') {
          this.errorMessage = this.translate.instant('loginIncorrectCredentials'); // Traducción de 'Incorrect user credentials'
        } else if (token.message === 'Company is not active') {
          this.errorMessage = this.translate.instant('loginIncorrectCompanyNoActive');
        } else {
          // Si el error es diferente, mostrar el mensaje tal cual lo devuelve el servidor
          this.errorMessage = token.message;
        }
      }
    }, err => {
      this.errorMessage = this.translate.instant('loginError'); // Error genérico de login
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  closeErrorModal() {
    this.errorMessage = '';
  }
}

