import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LocalService } from 'src/app/services/local.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private localService: LocalService) { }

  transform(value: any, ...args: unknown[]): any {
    let tokencito = this.localService.getJsonValue('token');
    if (tokencito?.data?.languagePreference == 'esp') {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    } else if (tokencito?.data?.languagePreference == 'eng') {
      return this.datePipe.transform(value, 'MM/dd/yyyy');
    } else {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    }
  }

}
