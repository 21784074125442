<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="pb-3 text-center title-text-modal">
  {{ esNuevo ? ('addBranchEditName' | translate) : ('addBranchCreateName' | translate) }}
</div>

<div class="md:px-6 px-0">
  <form [formGroup]="miFormulario" (ngSubmit)="submitForm()">
    <div class="pt-2 pb-3 flex flex-col">
      <label class="pr-6 pt-2 green-subtitle" for="nombre"> {{ 'addBranchName' | translate }}</label>
      <div class="flex">
        <input id="addBranchName" formControlName="nombre" class="p-2 w-full mt-1 borde" type="text" name="nombre"
          (keydown.enter)="addBranch($event)" maxlength="50"
          oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]+/g, "").trimStart();' />
        <div class="ml-2 mt-2">
          <a id="addBranchButton" mat-mini-fab class="mat-elevation-z0 flex justify-center items-center primary-color"
            *ngIf="!esNuevo" (click)="addBranch()" type="button">
            <mat-icon class="pb-1 add-branch-button">add</mat-icon>
          </a>
        </div>
      </div>
      <div
        *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)"
        class="text-red-500 text-xs mt-1">
        <span *ngIf="miFormulario.get('nombre').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addBranchName' | translate) } }}
        </span>
        <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
          {{ 'invalidCharactersKeyFormCatalogs' | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="!esNuevo" class="flex overflow-x-auto max-w-full">
      <ul class="flex space-x-4 py-2">
        <li *ngFor="let branch of listaSucursales; let i = index"
          class="bg-gray-200 rounded-full px-4 py-1 flex items-center space-x-2">
          <span>{{ branch.broF_NAME }}</span>
          <button id="deleteBranch" (click)="deleteBranch(i)" class="text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </li>
      </ul>
    </div>

    <div class="py-1" *ngIf="esNuevo">
      <label class="pr-6 pt-2 green-subtitle" for="descripcion ">{{ 'addBranchDescription' | translate
        }}</label>
      <textarea class="p-2 pb-1 w-full mt-1 borde" type="text" name="descripcion" rows="4" formControlName="descripcion"
        maxlength="500"
        oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]+/g, "").trimStart();'></textarea>

      <div
        *ngIf="miFormulario.get('descripcion').invalid && (miFormulario.get('descripcion').dirty || miFormulario.get('descripcion').touched)"
        class="text-red-500 text-xs mt-1">
        <span *ngIf="miFormulario.get('descripcion').errors['noSpecialCharacters']">
          {{ 'invalidCharactersKeyFormCatalogs' | translate }}
        </span>
      </div>

      <div *ngIf="hasSubdomain" class="w-full" tourAnchor="branch.location">
        <div class="pb-3 pt-1">
          <label class="pr-6 pt-2 green-subtitle" for="street"> {{ 'addStreetName' | translate }}</label>
          <div class="flex items-center gap-1">
            <input id="addStreetName" formControlName="street" class="p-2 w-full mt-1 borde" type="text" name="street" />
            <mat-icon tourAnchor="branch.eye" [matTooltip]="(!locationVisibility ? 'locationTooltip' : 'locationTooltip2') | translate"
            class="eye-icon w-20 h-20" [ngClass]="locationVisibility ? 'orange-color' : 'gray-color'"
            (click)="toggleLocationVisibility()">
            {{locationVisibility ? 'visibility' : 'visibility_off'}}
          </mat-icon>
          </div>
        </div>
        <div id="map" style="height: 200px; width: 100%;"></div>
      </div>
    </div>
    <div class="ml-auto md:w-full mt-6 flex flex-col md:flex-row md:justify-center md:items-center">
      <app-custom-primary-button id="saveNewBranchDialog" type="submit" size="super-small-light" color="primary"
        class="text-center" [disabled]="sending">
        <span *ngIf="!sending">{{ 'addDocumentSave' | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>

  </form>
</div>