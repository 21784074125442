import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-toggle-switch',
  templateUrl: './custom-toggle-switch.component.html',
  styleUrls: ['./custom-toggle-switch.component.css']
})
export class CustomToggleSwitchComponent  {

@Input() isChecked: boolean = false;
@Input() label: string = '';
@Output() toggle = new EventEmitter<boolean>();

onToggle() {
  this.isChecked = !this.isChecked;
  this.toggle.emit(this.isChecked);
}

}
