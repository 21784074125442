<div class="pb-6 text-2xl" style="color: #718096; border-bottom: 1px solid #EBEBEB;">
    {{ 'editStatusTitle' | translate }}
</div>
<div class="px-6">
    <div class="pt-6 pb-3">
        <label class="pr-6 pt-2 gris font-medium" for="nombre" style="color: #718096;">{{ 'editStatusName' | translate
            }}</label>
        <input id="editStatusName" class="p-2 md:w-full mt-1 borde" type="text" name="nombre">
    </div>

    <div class="py-3">
        <label class="pr-6 pt-2 gris font-medium" for="descripcion" style="color: #718096;">{{ 'editStatusDescription' |
            translate }}</label>
        <textarea id="editStatusDescription" class="p-2 md:w-full mt-1 borde" type="text" name="descripcion"
            rows="4"></textarea>
    </div>

    <div class="ml-auto md:w-2/5 mt-6">
        <app-custom-primary-button id="editStatusSave" type="submit" size="super-small-light" color="primary"
            class="w-full">
            {{ 'editStatusSave' | translate }}
        </app-custom-primary-button>
    </div>

</div>