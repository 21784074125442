<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="cerrar(false)" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="flex justify-between items-center px-3 pb-3 text-2xl borderr" >
  <div [innerHTML]="data[0]">
  </div>
</div>

<div class="py-6 pt-3 px-3 text-left mid-text line" [innerHTML]="data[1]"> </div>
<div class="flex flex-col md:flex-row justify-between mt-6 space-y-4 md:space-y-0 md:space-x-4">
  <div class="w-full md:flex-grow">
    <app-custom-primary-button id="confirmHistoryDialog" type="submit" size="mini" color="secondary" class="w-full"
    (click)="navigateTo('/home/updates')">
      {{ 'confirmHistoryDialog' | translate }}
    </app-custom-primary-button>
  </div>
  <div class="w-full md:w-auto">
    <app-custom-primary-button *ngIf="hasSubdomain" id="confirmDialogLoading" type="submit" size="mini" color="primary" class="w-full"
      (click)="cerrar(true)">
      {{ 'confirmDialogUpdate' | translate }}
    </app-custom-primary-button>
    <app-custom-primary-button *ngIf="!hasSubdomain" id="confirmDialogLoading" type="submit" size="mini" color="primary" class="w-full"
      (click)="cerrar(true)">
      {{ 'confirmDialog' | translate }}
    </app-custom-primary-button>
  </div>
</div>
