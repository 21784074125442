<div  class="custom-dropdown" [ngClass]="{'mobile-responsive': mobileResponsive}" [style.width]="width"
  [style.height]="height" (keydown.space)="toggleDropdown()" [tabindex]="tabindexnumber" >
  <div class="dropdown-selected" [style.height]="height" (click)="toggleDropdown()">
    {{ formattedSelectedItem || (selectedItem ? (translateLabels ?
    (selectedItem[displayProperty] | translate)
    : selectedItem[displayProperty]) : '') }}
    <img src="../../../../../assets/icons/dropdown-icon.svg" class="dropdown-icon" />
  </div>
  <div class="dropdown-items" *ngIf="dropdownOpen" [style.max-height.px]="maxHeight">
    <div class="dropdown-item" *ngFor="let item of items" (click)="onSelect(item)" (keypress)="onSelectKeyPress($event, item)" [class.active]="i === selectedIndex" [tabindex]="tabindexnumber"
      (mouseenter)="hoveredItem = item" (mouseleave)="hoveredItem = null" [class.hovered]="hoveredItem === item">
      {{ formatFunction ? formatFunction(item) : (translateLabels ? (item[displayProperty] | translate) :
      item[displayProperty]) }}
    </div>
  </div>
</div>
