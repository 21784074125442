<div *ngIf="showCatalogsEmptyMessage"
  class="flex flex-col justify-center items-center  top-0 left-0 right-0 bottom-0 m-auto w-full max-w-md overflow-auto p-4">
  <div>
    <div class="text-center title-text-modal ">{{"statusNoReasons" | translate}}</div>
    <div class="flex justify-center mt-10">
      <app-custom-primary-button id="catalogueComponentTitle" size="recluter-size" color="secondary"
        class="w-full md:w-auto" (click)="goToReasons()">
        {{ 'catalogueComponentTitle' | translate }}
      </app-custom-primary-button>
    </div>
  </div>
</div>

<div *ngIf="!showCatalogsEmptyMessage">
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>

  <div *ngIf="progressMode==0">
    <div class="flex justify-center items-center pb-6 title-text relative">
      <div class="text-center">
        {{progressHeader}}
      </div>
    </div>

    <div class="pl-6 subtitle-text">
      {{ 'changeStatusDescription' | translate }}
    </div>
    <div class="px-6">
      <div class="pt-6 pb-3  w-full">
        <label for="progreso" class="input-text">
          {{ 'changeStatusTitle' | translate }}
        </label>
        <mat-select id="changeStatusTitle" [formControl]="params.controls['progress']" [compareWith]="compareProgress"
          name="progressSelection" class="borde p-3 centered-text brown-border">
          <mat-option *ngFor="let progress of progressList" [value]="progress">
            {{ progress.stS_NAME | translate }}
          </mat-option>
        </mat-select>
      </div>
      <div class="py-3 w-full">
        <label class="pr-6 pt-2 input-text">
          {{ 'changeStatusMessage' | translate }}
        </label>
        <div class="flex flex-col pb-4 w-full">
          <input id="changeStatusMessage" class="borde p-3 centered-text brown-border w-full"
            [formControl]="params.get('name')" [placeholder]="'placeholderMessage' | translate" [matAutocomplete]="auto"
            (input)="onInput($event)" #autocompleteTrigger="matAutocompleteTrigger" #inputElement
            (keydown.enter)="onEnterPress($event)" maxlength="2000" oninput="this.value = this.value.trimStart();">
          <!-- <mat-autocomplete #auto="matAutocomplete" (opened)="isAutocompleteOpen = true"
            (closed)="isAutocompleteOpen = false">
            <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario.nombre"
              (onSelectionChange)="onUserSelect(usuario.nombre)">
              {{ usuario.nombre }}
            </mat-option>
          </mat-autocomplete> -->
        </div>
      </div>
      <div class="md:w-2/5 mt-6">
        <app-custom-primary-button id="changeStatusSave" type="button" size="super-small-light" color="primary"
          class="w-full" (click)="save()">
          {{ 'changeStatusSave' | translate }}
        </app-custom-primary-button>
      </div>
    </div>
  </div>

  <div *ngIf="progressMode==1">
    <div class="flex justify-center items-center pb-6 title-text relative">
      <div class="text-center">
        {{progressHeader}}
      </div>
    </div>
    <div class="pl-6 subtitle-text">
      {{ 'changeStatusDescription' | translate }}
    </div>
    <div class="px-6">
      <div class="px-6">
        <div class="pt-6 pb-3  w-full">
          <label for="progreso" class="input-text">
            {{ 'changeStatusPhase' | translate }}
          </label>
          <mat-select id="changeStatusPhase" [formControl]="params.controls['status']" [compareWith]="compareStatus"
            name="statusSelection" class="borde p-3 centered-text brown-border" (selectionChange)="onStatusChange()">
            <mat-option *ngFor="let status of statusList" [value]="status">
              {{ getTranslatedStatus(status.stsC_NAME) }}
            </mat-option>
          </mat-select>
        </div>

        <!-- Razones de descarte -->
        <div *ngIf="params.controls['status'].value?.stsC_ID == 'e7630126-4719-4e8d-8ab7-8146d06bc6ff'"
          class="py-3 w-full">
          <label for="reason" class="input-text">
            {{ 'changeStatusPhaseReason' | translate }}
          </label>
          <mat-select id="changeStatusPhaseReason" [formControl]="params.controls['dropSource']" name="reason"
            class="borde p-3 centered-text brown-border">
            <mat-option *ngFor="let reason of reasonsList" [value]="reason">
              {{ reason.droP_SOURCE_NAME | translate }}
            </mat-option>
          </mat-select>
        </div>

        <!-- Fecha de contratación -->
        <div *ngIf="params.controls['status'].value?.stsC_ID == '4c19c9dc-badd-4fbd-8fd6-d08307ddfe0c'"
          class="flex flex-col w-full">
          <span class="pb-1 title-input-text">{{ 'candidateDetailHiredDate' | translate }}</span>
          <div class="h-full">
            <app-lang-datepicker id="hiredDate" [date]="hiredVacancyDate" [min]="minDate" [tabindex]="11"
              (datechange)="hiredVacancyDate = $event" [borde]="'#EBEBEB'"></app-lang-datepicker>
          </div>
        </div>

        <div class="py-3 w-full">
          <label class="pr-6 pt-2 input-text">
            {{ 'changeStatusMessage' | translate }}
          </label>
          <div class="flex flex-col pb-4 w-full">
            <input id="changeStatusMessage" class="borde p-3 centered-text brown-border w-full"
              [formControl]="params.get('name')" [placeholder]="'placeholderMessage' | translate"
              [matAutocomplete]="auto" (input)="onInput($event)" #autocompleteTrigger="matAutocompleteTrigger"
              #inputElement (keydown.enter)="onEnterPress($event)" maxlength="2000"
              oninput="this.value = this.value.trimStart();">
            <!-- <mat-autocomplete #auto="matAutocomplete" (opened)="isAutocompleteOpen = true"
              (closed)="isAutocompleteOpen = false">
              <mat-option *ngFor="let usuario of usuariosFiltrados" [value]="usuario.nombre"
                (onSelectionChange)="onUserSelect(usuario.nombre)">
                {{ usuario.nombre }}
              </mat-option>
            </mat-autocomplete> -->
          </div>

          <!-- Checkbox enviar correo -->
          <div tourAnchor="discard.mail.check"
            *ngIf="hasSubdomain && params.controls['status'].value?.stsC_ID == 'e7630126-4719-4e8d-8ab7-8146d06bc6ff'"
            class="flex flex-col w-full overflow-hidden items-start checkbox-discard">
            <mat-checkbox [(ngModel)]="sendInstantMail" id="sendEmail" class="w-full">
              <span class="text-xs text-gray-500 whitespace-normal break-words">
                {{ 'sendEmailInstantDiscard' | translate }}
              </span>
            </mat-checkbox>
          </div>
        </div>

        <div class="md:w-2/5 mt-6">
          <app-custom-primary-button id="changeStatusSave2" type="submit" size="super-small-light" color="primary"
            class="w-full" (click)="save()">
            {{ 'changeStatusSave' | translate }}
          </app-custom-primary-button>
        </div>
      </div>
    </div>
  </div>
</div>