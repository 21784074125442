<div class="flex justify-end pt-4 px-10">
  <select id="languageSelectReset" (change)="changeLanguage($event.target.value)" [value]="currentLang"
    class="block appearance-none bg-white border border-gray-300 hover:border-gray-400 px-2 py-1 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
    <option value="es" [selected]="currentLang === 'esp'">Español</option>
    <option value="en" [selected]="currentLang === 'eng'">English</option>
  </select>
</div>

<div class="flex flex-col items-center justify-center h-full pt-20 md:px-0 px-10">
  <div class="w-376 h-504 shadow-lg rounded-sm bg-white flex flex-col items-center py-10 px-6">
    <div class="login-title mb-10 w-full text-center" [innerHTML]="recoverTitlePassword"></div>
    <div class="w-full">
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <div class="w-full mb-4">
          <div class="login-input-label mb-1">{{ recoverEmailPassword }}</div>
          <div class="flex items-center">
            <app-custom-input id="newPassword" formControlName="newPassword" [type]="hide ? 'password' : 'text'" name="usuario"
              class="w-full" placeholder="•••••••••" required></app-custom-input>
            <mat-icon class="cursor-pointer pl-1 ml-2" (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility"
              }}</mat-icon>
          </div>
          <div *ngIf="resetForm.controls['newPassword'].errors?.required && resetForm.controls['newPassword'].touched"
            class="alert alert-danger text-red-500">
            No has ingresado la nueva contraseña.
          </div>
        </div>
      </form>
    </div>

    <div class="w-full">
      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <div class="w-full mb-6">
          <div class="login-input-label mb-1">{{ recoverButtonPassword }}</div>
          <div class="flex items-center">
            <app-custom-input id="confirmPassword" formControlName="confirmPassword" [type]="hide2 ? 'password' : 'text'" name="usuario"
              class="w-full" placeholder="•••••••••" required></app-custom-input>
            <mat-icon class="cursor-pointer pl-1 ml-2" (click)="hide2 = !hide2">{{ hide2 ? "visibility_off" : "visibility"
              }}</mat-icon>
          </div>
          <div *ngIf="resetForm.controls['newPassword'].errors?.required && resetForm.controls['newPassword'].touched"
            class="alert alert-danger text-red-500">
            No has ingresado la nueva contraseña.
          </div>
          <div
            *ngIf="resetForm.controls['confirmPassword'].errors?.required && resetForm.controls['confirmPassword'].touched"
            class="alert alert-danger text-red-500">
            No has confirmado la nueva contraseña.
          </div>
          <div *ngIf="passwordMismatch" class="alert alert-danger text-red-500">
            Las contraseñas no coinciden.
          </div>
        </div>
      </form>
    </div>

    <div class="w-full">
      <app-custom-primary-button id="resetPasswordConfirm" (click)="onSubmit()" class="w-full" color="primary" size="auto">
        {{'resetPasswordConfirm' | translate }}
      </app-custom-primary-button>
    </div>
  </div>
</div>