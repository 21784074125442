import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Job } from 'src/app/interfaces/job';
import { Questionnaire } from 'src/app/interfaces/questionnaire';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-add-or-edit-job-questionnaire',
  templateUrl: './add-or-edit-job-questionnaire.component.html',
  styleUrls: ['./add-or-edit-job-questionnaire.component.css']
})
export class AddOrEditJobQuestionnaireComponent implements OnInit {

  questionnaire: Questionnaire;
  job: Job;
  newQuest: boolean = false;
  sending: boolean = false;
  setedTourQuestion: boolean = false;
  @ViewChild('formContainer') formContainer: ElementRef;

  percentValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
    const percent1 = Number(formGroup.get('percent1')?.value);
    const percent2 = Number(formGroup.get('percent2')?.value);

    if (percent1 && percent2 && percent2 > percent1) {
      return { biggerThanPercent1: true }; // Error si percent2 es mayor que percent1
    }

    return null; // Sin error si percent2 es menor o igual a percent1
  };

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [Validators.required, this.noSpecialCharactersValidator]],
    descripcion: ['', [this.noSpecialCharactersValidator]],
    percent1: [''],
    percent2: ['']
  }, { validators: this.percentValidator.bind(this) });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/(),"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  constructor(
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<AddOrEditJobQuestionnaireComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Job,
  ) {
    this.job = data;
    this.questionnaire = this.job.questionnaire;
    if (this.job.questionnairE_ID == null) {
      this.newQuest = true;
    } else {
      this.questionnaire.questionnairE_NAME = this.job.questionnairE_NAME;
      this.questionnaire.questionnairE_DESCRIPTION = this.job.questionnairE_DESCRIPTION;
      this.questionnaire.questionnairE_MINIMUM_PASSING_SCORE = this.job.questionnairE_MINIMUM_PASSING_SCORE;
      this.questionnaire.questionnairE_EXPECTED_SCORE = this.job.questionnairE_EXPECTED_SCORE;
    }

    if (!this.questionnaire || !this.questionnaire.questions) {
      this.questionnaire = {
        questionnairE_NAME: "",
        questionnairE_DESCRIPTION: "",
        questions: [
          {
            questioN_TEXT: "",
            questioN_TYPE: "OPEN_ENDED",
            positioN_NUMBER: 1,
            options: []
          },
          {
            questioN_TEXT: "",
            questioN_TYPE: "YES_NO",
            positioN_NUMBER: 2,
            options: [
              {
                optioN_TEXT: "Yes",
                iS_CORRECT: true,
                optioN_POSITION_NUMBER: 1
              },
              {
                optioN_TEXT: "No",
                iS_CORRECT: false,
                optioN_POSITION_NUMBER: 2
              }
            ]
          }
        ]
      }
    }
  }

  ngOnInit(): void {
    this.miFormulario.reset({
      nombre: this.questionnaire.questionnairE_NAME,
      descripcion: this.questionnaire.questionnairE_DESCRIPTION,
      percent2: this.questionnaire.questionnairE_MINIMUM_PASSING_SCORE,
      percent1: this.questionnaire.questionnairE_EXPECTED_SCORE
    });
    this.questionnaire.jobs = [
      {
        JOB_ID: this.job.joB_ID
      }
    ]
  }

  createOrUpdateQuestionnaire() {
    if (this.sending) {
      return;
    }

    if (this.miFormulario.invalid) {
      this.miFormulario.markAllAsTouched();
      return;
    }

    const someFieldEmpty = this.questionnaire.questions.some(question => !question.questioN_TEXT || question.questioN_TEXT.trim() === '' || question.options.some(option => option ? (!option.optioN_TEXT || option.optioN_TEXT.trim() === '') : false));
    if (someFieldEmpty) {
      return;
    }

    // if questioN_TYPE is CHECKBOXES at least one option must be correct
    const someCheckboxWithoutCorrectOption = this.questionnaire.questions.some(question => question.questioN_TYPE === 'CHECKBOXES' && !question.options.some(option => option.iS_CORRECT));
    if (someCheckboxWithoutCorrectOption) {
      return;
    }

    this.sending = true;

    this.questionnaire.questionnairE_NAME = this.miFormulario.get('nombre')?.value;
    this.questionnaire.questionnariE_DESCRIPTION = this.miFormulario.get('descripcion')?.value;
    this.questionnaire.questionnairE_MINIMUM_PASSING_SCORE = this.miFormulario.get('percent2')?.value ? Number(this.miFormulario.get('percent2')?.value) > 0 ? Number(this.miFormulario.get('percent2')?.value) : null : null;
    this.questionnaire.questionnairE_EXPECTED_SCORE = this.miFormulario.get('percent1')?.value ? Number(this.miFormulario.get('percent1')?.value) > 0 ? Number(this.miFormulario.get('percent1')?.value) : null : null;
    delete this.questionnaire.createD_DATE;

    this.questionnaire = this.convertKeysToUpperCase(this.questionnaire);

    this.puzzleService.createOrUpdateQuestionnaire(this.questionnaire).subscribe((res) => {
      if (res.isSuccess) {
        this.dialogRef.close({
          isSuccess: true,
          message: res.message
        });
      } else {
        this.sending = false;
        this.dialogRef.close({
          isSuccess: false,
          message: res.message
        });
      }
    })
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questionnaire.questions, event.previousIndex, event.currentIndex);
    // update key positioN_NUMBER for each option
    this.questionnaire.questions.forEach((question, index) => {
      question.positioN_NUMBER = index + 1;
    });
  }

  convertKeysToUpperCase(obj) {
    if (Array.isArray(obj)) {
      // Si es un array, aplica la función a cada elemento
      return obj.map(item => this.convertKeysToUpperCase(item));
    } else if (obj && typeof obj === 'object') {
      // Si es un objeto, convierte sus claves a mayúsculas
      const result = {};

      Object.keys(obj).forEach(key => {
        const upperCaseKey = key.toUpperCase();
        const value = obj[key];

        // Aplicar la función recursivamente a objetos y arrays
        result[upperCaseKey] = this.convertKeysToUpperCase(value);
      });

      return result;
    }

    // Si no es un objeto o array, simplemente devuelve el valor
    return obj;
  }

  addQuestion() {
    this.questionnaire.questions.push({
      questioN_TEXT: "",
      questioN_TYPE: "OPEN_ENDED",
      positioN_NUMBER: this.questionnaire.questions.length + 1,
      options: []
    });

    // scroll to the bottom of formContainer
    setTimeout(() => {
      this.formContainer.nativeElement.scrollTo({
        top: this.formContainer.nativeElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  }

  dropQuestion(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.questionnaire.questions, event.previousIndex, event.currentIndex);

    // update key positioN_NUMBER for each option
    this.questionnaire.questions.forEach((question, index) => {
      question.positioN_NUMBER = index
    });
  }

  duplicateQuestion(index: number) {
    const question = this.questionnaire.questions[index];
    const duplicatedQuestion = {
      questioN_TEXT: question.questioN_TEXT,
      questioN_TYPE: question.questioN_TYPE,
      options: question.options,
      positioN_NUMBER: index + 1
    };

    // reassign positioN_NUMBER for each question
    this.questionnaire.questions.forEach((question, i) => {
      question.positioN_NUMBER = i + 1;
    });

    this.questionnaire.questions.splice(index + 1, 0, JSON.parse(JSON.stringify(duplicatedQuestion)));
  }

  deleteQuestion(index: number) {
    this.questionnaire.questions.splice(index, 1);
  }

  closeModal() {
    this.dialogRef.close();
  }

  getSetedQuestions(question, i) {
    if (!this.setedTourQuestion && question.questioN_TYPE != 'OPEN_ENDED') {
      this.setedTourQuestion = true;
      return false;
    } else {
      return true;
    }
  }
}
