import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalService } from '../services/local.service';
import { constants } from '../services/constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyRedirectGuard implements CanActivate {
  constructor(private router: Router, private localService: LocalService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const urlWithoutParams = state.url.split('?')[0];
    const urlSegments = urlWithoutParams.split('/').filter(segment => segment !== '');
    let tokencito = this.localService.getJsonValue('token');
    if (tokencito.data.roleId == constants.superadmin) {
      return true;
    }
    const companyNameToken = this.removeSpecialCharactersAndSpaces(tokencito.data.companyName)
    if (urlSegments[0] !== 'home' && !urlSegments.includes(companyNameToken)) {
      urlSegments.shift();
    }
    if (urlSegments.includes('home') && !urlSegments.includes(companyNameToken)) {
      const companyName = companyNameToken;
      if (companyName) {
        const routeSegments = [companyName, ...urlSegments];
        let newUrl = '/' + routeSegments.join('/');
        newUrl = newUrl.split('?')[0];
        const originalParams = state.url.split('?')[1];
        const queryParams = originalParams ? { queryParams: { ...this.router.parseUrl(state.url).queryParams } } : null;
        if (queryParams) {
          return this.router.createUrlTree([newUrl], queryParams);
        } else {
          return this.router.createUrlTree([newUrl]);
        }
      }
    }

    return true;
  }

  removeSpecialCharactersAndSpaces(text: string): string {
    text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }
}
