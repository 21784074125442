<div>
  <div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>
  <div class="text-center title-text-modal">
    {{"userPasswordDialogTitle" | translate}}
  </div>
  <form (ngSubmit)="sendPassChange()" autocomplete="off" [formGroup]="myForm" class="w-full">
    <div class="pt-6 mb-4">
      <label class="title-input-text green-subtitle" for="viejacontra">{{"userPasswordDialogCurrentPassword" |
        translate}}</label>
      <div class="input-container mobile-responsive relative mt-1">
        <input id="pastPass" formControlName="password" [type]="hide ? 'password' : 'text'" class="custom-input"
          placeholder="" name="viejacontra">
        <mat-icon
          class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
          matSuffix (click)="hide = !hide">
          {{ hide ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div *ngIf="myForm.get('password').invalid && (myForm.get('password').dirty || myForm.get('password').touched)"
        class="text-red-500 text-xs mt-2 -mb-2">
        <span *ngIf="myForm.get('password').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userPasswordDialogCurrentPassword' | translate) } }}
        </span>
      </div>
    </div>
    <div class="mb-4">
      <label class="title-input-text green-subtitle" for="nuevacontra">{{"userPasswordDialogNewPassword" |
        translate}}</label>
      <div class="input-container mobile-responsive relative mt-1">
        <input id="newPass" formControlName="new_password" [type]="hide2 ? 'password' : 'text'" class="custom-input"
          placeholder="" name="nuevacontra">
        <mat-icon
          class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
          matSuffix (click)="hide2 = !hide2">
          {{ hide2 ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div
        *ngIf="myForm.get('new_password').invalid && (myForm.get('new_password').dirty || myForm.get('new_password').touched)"
        class="text-red-500 text-xs mt-2 -mb-2">
        <span *ngIf="myForm.get('new_password').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userPasswordDialogNewPassword' | translate) } }}
        </span>
        <span *ngIf="myForm.get('new_password').errors['minlength']">
          {{ 'addDialogPasswordAlertMin' | translate }}
        </span>
      </div>
    </div>
    <div class="mb-4">
      <label class="title-input-text green-subtitle" for="confirnuevacontra">{{"userPasswordDialogConfirmPassword" |
        translate}}</label>
      <div class="input-container mobile-responsive relative mt-1">
        <input id="confirmNewPass" formControlName="new_password2" [type]="hide3 ? 'password' : 'text'"
          class="custom-input" placeholder="" name="confirnuevacontra">
        <mat-icon
          class="cursor-pointer gray-me p-1.5 icon-password absolute right-0 top-1/2 transform -translate-y-1/2 mr-3"
          matSuffix (click)="hide3 = !hide3">
          {{ hide3 ? "visibility_off" : "visibility" }}
        </mat-icon>
      </div>
      <div
        *ngIf="myForm.get('new_password2').invalid && (myForm.get('new_password2').dirty || myForm.get('new_password2').touched)"
        class="text-red-500 text-xs mt-2 -mb-2">
        <span *ngIf="myForm.get('new_password2').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('userPasswordDialogConfirmPassword' | translate) } }}
        </span>
        <span *ngIf="!myForm.get('new_password2').errors['required'] && myForm.get('new_password2').errors['noIguales']">
          {{ 'passwordDialogPasswordMatch' | translate }}
        </span>
      </div>
    </div>
    <div class="w-full text-right justify-end pt-2">
      <app-custom-primary-button id="passwordDialogSaveBtn" class="w-full" type="submit" size="small" color="primary">
        <span *ngIf="!sending">{{"userPasswordDialogSave" | translate}}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>
  </form>
</div>