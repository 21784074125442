import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appFormatCurrencyDirective]',
  providers: [NgModel] // Añadir el proveedor de NgModel
})

export class FormatCurrencyDirectiveDirective {

  @Output() numericValue = new EventEmitter<number>();

  constructor(private el: ElementRef, private model: NgModel, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initialValue = this.el.nativeElement.value;
    initialValue = initialValue.replace(/\D/g, '');

    if (initialValue.length <= 2) {
      initialValue = initialValue.padStart(2, '0');
      initialValue = `0.${initialValue}`;
    } else {
      initialValue = initialValue.slice(0, initialValue.length - 2) + '.' + initialValue.slice(initialValue.length - 2);
    }

    const formattedValue = '$' + parseFloat(initialValue).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    // Actualizar el valor en el modelo
    this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
    this.model.control.setValue(formattedValue);

    // Emitir el valor numérico
    this.numericValue.emit(parseFloat(initialValue));

    if (this.el.nativeElement.value !== initialValue) {
      event.stopPropagation();
    }
  }
}