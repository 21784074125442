<div class="ml-auto w-full text-right justify-end -mt-4 -mr-3">
  <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
    <mat-icon class="close-x-primary">close</mat-icon>
  </button>
</div>

<div class="pb-3 text-center title-text-modal">
  <span [innerHTML]="modalTitle"></span>
</div>

<div class="md:px-6 px-0">
  <form [formGroup]="miFormulario" (ngSubmit)="submitForm()" *ngIf="!modoActivar">
    <div class="pt-6 pb-3 flex flex-col">
      <label class="pr-6 pt-2 green-subtitle" for="nombre">{{ 'addDocumentName' | translate }}</label>
      <div class="flex">
        <input id="addDocumentName" formControlName="nombre" class="p-2 w-full mt-1 borde" type="text" name="nombre"
          (keydown.enter)="agregarDocumento($event)" maxlength="50" oninput='this.value = this.value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]+/g, "").trimStart();' />
        <div class="ml-2 mt-2">
          <a id="addDocumentButton" mat-mini-fab
            class="mat-elevation-z0 flex justify-center items-center mat-mini-fab-style" *ngIf="!esNuevo"
            (click)="agregarDocumento()" type="button">
            <mat-icon class="pb-1 mat-icon-style">add</mat-icon>
          </a>
        </div>
      </div>
      <div
        *ngIf="miFormulario.get('nombre').invalid && (miFormulario.get('nombre').dirty || miFormulario.get('nombre').touched)"
        class="text-red-500 text-xs mt-1">
        <span *ngIf="miFormulario.get('nombre').errors['required']">
          {{ 'hasWhiteSpaceKeyForm' | translate: { key: ('addDocumentName' | translate) } }}
        </span>
        <span *ngIf="miFormulario.get('nombre').errors['noSpecialCharacters']">
          {{ 'invalidCharactersKeyFormCatalogs' | translate }}
        </span>
      </div>
    </div>

    <div *ngIf="!esNuevo" class="flex overflow-x-auto max-w-full">
      <ul class="flex space-x-4 py-2">
        <li *ngFor="let documento of listaDocumentos; let i = index"
          class="bg-gray-200 rounded-full px-4 py-1 flex items-center space-x-2">
          <span>{{ documento.pdT_NAME }}</span>
          <button id="deleteDocumentDialog" (click)="eliminarDocumento(i)" class="text-red-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </li>
      </ul>
    </div>

    <div class="ml-auto md:w-full mt-6 flex flex-col md:flex-row md:justify-center md:items-center">
      <app-custom-primary-button id="addDocumentSave" type="submit" size="super-small-light" color="primary"
        class="text-center" [disabled]="sending">
        <span *ngIf="!sending">{{ 'addDocumentSave' | translate }}</span>
        <div *ngIf="sending" class="w-full flex justify-center">
          <mat-spinner diameter="20" color="primary"></mat-spinner>
        </div>
      </app-custom-primary-button>
    </div>
  </form>


  <div *ngIf="modoActivar && document.pdT_NAME !== 'CV'"
    class="action-buttons flex flex-col items-center justify-center ">
    <div *ngIf="!document.cdoC_LINK_ACTIVE" class="pb-6 pt-3 title-text-modal text-center">
      {{'addDocumentTitleActivateText' | translate}}
    </div>
    <app-custom-primary-button *ngIf="!document.cdoC_LINK_ACTIVE" (click)="toggleDocumentActiveState(true)" size="small"
      color="primary">
      {{'resetPasswordConfirm' | translate}}
    </app-custom-primary-button>
    <div *ngIf="document.cdoC_LINK_ACTIVE" class="pb-6 pt-3 title-text-modal text-center">
      {{'addDocumentTitleDesctivateText' | translate}}
    </div>
    <app-custom-primary-button *ngIf="document.cdoC_LINK_ACTIVE" (click)="toggleDocumentActiveState(false)" size="small"
      color="primary">
      {{'resetPasswordConfirm' | translate}}
    </app-custom-primary-button>
  </div>

</div>