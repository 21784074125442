import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HomeComponent } from '../../home/home.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  disableClose: boolean = false;

  constructor(private dialogRef: MatDialogRef<HomeComponent>, @Inject(MAT_DIALOG_DATA) public data: string[]) { }

  ngOnInit(): void {
    this.disableClose = Boolean(this.data[2]);
  }

  cerrar(option: boolean) {
    if (option) {
      this.dialogRef.close(true);
    }
    else {
      this.dialogRef.close();
    }
  }
}
