import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { ValidatorService } from '../../services/validator.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PuzzleService } from '../../services/puzzle.service';
import { HomeComponent } from '../../home/home.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Branch } from '../../interfaces/branch';
import { TranslateService } from '@ngx-translate/core';
import { Loader } from '@googlemaps/js-api-loader';
import { APIResponse } from 'src/app/interfaces/response';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-add-or-edit-branch',
  templateUrl: './add-or-edit-branch.component.html',
  styleUrls: ['./add-or-edit-branch.component.css']
})
export class AddOrEditBranchComponent implements OnInit {

  sendBranch: Branch = {
    broF_COMPANY_ID: '',
    broF_NAME: '',
    broF_DESCRIPTION: '',
    broF_ID: '',
  };

  listaSucursales: Branch[] = [];
  esNuevo: boolean = false;
  tabCount: number = 0;
  sending: boolean = false;
  address: string = '';
  locationVisibility: boolean = false;
  hasSubdomain: boolean = false;
  token: APIResponse = this.localService.getJsonValue('token');

  center: google.maps.LatLngLiteral = { lat: 19.432608, lng: -99.133209 }; // Ciudad de México por defecto
  zoom = 18;
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  marker: google.maps.Marker;
  autocomplete: google.maps.places.Autocomplete;

  @HostListener('document:keydown', ['$event'])
  handleKeyPress(event: KeyboardEvent) {

    if (event.key === 'Tab') {
      this.tabCount++;
      if (this.tabCount === 2) {
        const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
        if (submitButton) {
          submitButton.focus();
        }
      }
    } else if (event.key !== 'Enter') {
      this.tabCount = 0;
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  handleTabPress(event: KeyboardEvent) {

    this.tabCount++;
    if (this.tabCount === 2) {
      const submitButton = this.elementRef.nativeElement.querySelector('button[type="submit"]');
      if (submitButton) {
        submitButton.focus();
      }
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterPress(event: KeyboardEvent) {
    if (this.esNuevo) return;
    if (this.tabCount === 1) {
      event.preventDefault();
      if (!this.esNuevo) {
        this.addBranch();
      }
    } else if (this.tabCount >= 2) {
      event.preventDefault();
      this.submitForm();
    }
    this.tabCount = 0;
  }


  constructor(
    private elementRef: ElementRef,
    private validatorService: ValidatorService,
    private fb: FormBuilder,
    private puzzleService: PuzzleService,
    private dialogRef: MatDialogRef<HomeComponent>,
    private translate: TranslateService,
    private localService: LocalService,
    @Inject(MAT_DIALOG_DATA) public data: Branch | string
  ) { }

  miFormulario: FormGroup = this.fb.group({
    nombre: ['', [this.isRequiredIfEmptyArray.bind(this), this.noSpecialCharactersValidator]],
    descripcion: ['', [this.noSpecialCharactersValidator]],
    street: [''],
    city: [''],
    state: [''],
    country: [''],
    countryCode: ['']
  });

  public noSpecialCharactersValidator(control: FormControl) {
    const value = control.value || '';
    // Expresión regular que permite letras (de cualquier alfabeto), números, espacios en blanco, -, /, (, ) y "
    const regex = /^[\p{L}\p{N}\s.\-\/()"]*$/u;

    if (!regex.test(value)) {
      return { 'noSpecialCharacters': true };
    }

    return null;
  }

  public isRequiredIfEmptyArray(control: FormControl) {
    const value = control.value || '';

    // Supongo que listaSucursales es una propiedad del componente que contiene el array.
    if (this.listaSucursales.length === 0 && !value.trim()) {
      return { 'required': true };
    }

    return null;
  }

  ngOnInit(): void {
    if (!(typeof this.data == 'string')) {
      this.esNuevo = true;
      this.miFormulario.reset({
        nombre: this.transformValue(this.data.broF_NAME),
        descripcion: this.transformValue(this.data.broF_DESCRIPTION),
        street: this.data.broF_STREET_NAME,
        city: this.data.broF_CITY,
        state: this.data.broF_STATE,
        country: this.data.broF_COUNTRY_NAME,
        countryCode: this.data.broF_COUNTRY_ISO
      })

      // si no hay country code pero hay country name buscar la dirección
      if (this.data.broF_COUNTRY_NAME && !this.data.broF_COUNTRY_ISO) {
        this.getAddress(this.center.lat, this.center.lng);
      }

      this.hasSubdomain = this.token?.data?.comP_HAS_SUBDOMAIN;

      if (this.hasSubdomain) {
        this.address = this.data.broF_STREET_NAME;
        this.locationVisibility = this.data.broF_VISIBILITY;

        this.loadGoogleMaps();
      }
    }
  }

  loadGoogleMaps() {
    const loader = new Loader({
      apiKey: 'AIzaSyCn9R2U3SckvswNEoCrw-3rQZ8JjIg0aRA', // Aquí va tu API Key de Google Maps
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      if (typeof this.data != 'string' && this.data.broF_LATITUDE && this.data.broF_LONGITUDE) {
        this.center = {
          lat: parseFloat(this.data.broF_LATITUDE),
          lng: parseFloat(this.data.broF_LONGITUDE)
        };
        this.initMap();
      } else if (typeof this.data != 'string') {
        this.initMap();
        this.getUserLocation();
      }

      // Inicializar el Autocomplete de Google Places
      const inputElement = document.getElementById('addStreetName') as HTMLInputElement;
      this.autocomplete = new google.maps.places.Autocomplete(inputElement, {
        fields: ['geometry', 'formatted_address', 'address_components'],
        types: ['address'], // Para limitar a direcciones
      });

      // Evento que se ejecuta cuando el usuario selecciona una dirección
      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete?.getPlace();
        if (place?.geometry?.location) {
          const location = place.geometry.location;
          const formattedAddress = place.formatted_address;
          const addressComponents = place.address_components;

          // Actualizar el campo "street" con la dirección seleccionada
          this.miFormulario.patchValue({
            street: formattedAddress,
          });

          // Mover el mapa y el marcador a la nueva ubicación
          this.map?.setCenter(location);
          this.marker?.setPosition(location);
          this.map?.setZoom(20); // Acercar al seleccionar una dirección

          // Variables para almacenar los diferentes componentes de la dirección
          let ciudad = '';
          let estado = '';
          let pais = '';
          let countryCode = '';

          // Iterar sobre los componentes de la dirección y asignarlos según el tipo
          addressComponents.forEach(component => {
            const componentType = component.types[0];

            switch (componentType) {
              case 'locality':
                ciudad = component.long_name;
                break;
              case 'administrative_area_level_1':
                estado = component.long_name;
                break;
              case 'country':
                pais = component.long_name;
                countryCode = component.short_name;
                break;
              default:
                break;
            }
          });

          this.miFormulario.get('city')?.setValue(ciudad);
          this.miFormulario.get('state')?.setValue(estado);
          this.miFormulario.get('country')?.setValue(pais);
          this.miFormulario.get('countryCode')?.setValue(countryCode);
          this.center = {
            lat: location.lat(),
            lng: location.lng()
          };
        }
      });
    });
  }

  addBranch(event?: Event) {
    if (!this.esNuevo) {

      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.miFormulario.markAllAsTouched();
      const nombreValue = this.miFormulario.get('nombre').value || '';
      if (nombreValue.trim() !== '' && this.miFormulario.valid) {
        // validar que no exista el nombre en la lista
        const existeNombre = this.listaSucursales.find(sucursal => sucursal.broF_NAME === nombreValue);
        if (!existeNombre) {
          const nuevaSucursal: Branch = this.createBranch();
          this.listaSucursales.unshift(nuevaSucursal);
          this.miFormulario.reset();
        } else {
          this.miFormulario.get('nombre')?.setValue('');
        }
      }
    }

  }

  createBranch(): Branch {
    let branchId: string | null = null;
    if (typeof this.data !== 'string' && this.data.broF_ID) {
      branchId = this.data.broF_ID;
    }
    return {
      broF_COMPANY_ID: typeof this.data === 'string' ? this.data : this.data.broF_COMPANY_ID,
      broF_NAME: this.miFormulario.get('nombre').value,
      broF_DESCRIPTION: this.miFormulario.get('descripcion').value,
      broF_ID: branchId,
      broF_LATITUDE: this.center.lat.toString(),
      broF_LONGITUDE: this.center.lng.toString(),
      broF_VISIBILITY: this.locationVisibility,
      broF_STREET_NAME: this.miFormulario.get('street').value,
      broF_CITY: this.miFormulario.get('city').value,
      broF_STATE: this.miFormulario.get('state').value,
      broF_COUNTRY_NAME: this.miFormulario.get('country').value,
      broF_COUNTRY_ISO: this.miFormulario.get('countryCode').value
    };
  }

  async submitForm() {
    this.miFormulario.markAllAsTouched();
    if (this.miFormulario.valid) {
      const nombreValue = this.miFormulario.get('nombre').value || '';
      const descripcionValue = this.miFormulario.get('descripcion').value || '';
      if (nombreValue.trim() !== '') {
        const sucursal = {
          ...this.createBranch(),
          broF_DESCRIPTION: descripcionValue
        };
        if (this.listaSucursales.length === 0) {
          this.sentBranch(sucursal);
          return;
        } else {
          this.listaSucursales.push(sucursal);
        }
      }
      const sucursalesParaEnviar = this.listaSucursales.map(sucursal => ({
        ...sucursal,
        broF_DESCRIPTION: sucursal.broF_DESCRIPTION || ''
      }));
      if (sucursalesParaEnviar.length > 0) {
        let ultimoNumeroFase = 0;
        let responses = [];
        this.sending = true;

        const promises = sucursalesParaEnviar.map(async (sucursal, index) => {
          sucursal.broF_NUMBER = ultimoNumeroFase + 1 + index;

          try {
            const response = await this.puzzleService.createOrEditBranch(sucursal).toPromise();
            return {
              isSuccess: response.isSuccess,
              message: this.translate.instant(response.message),
              name: sucursal.broF_NAME
            };
          } catch (error) {
            const errorMessage = this.translate.instant('addDepartamentAlert');
            return { isSuccess: false, message: errorMessage, name: sucursal.broF_NAME };
          }
        });

        try {
          responses = await Promise.all(promises);
        } catch (error) {
          console.error("Error en las promesas:", error);
        }

        this.dialogRef.close(responses);
      } /* else {
        this.dialogRef.close([]);
      } */
    }
  }

  sentBranch(sucursal) {
    this.sending = true;
    this.puzzleService.createOrEditBranch(sucursal).subscribe(
      response => {
/*         console.log(response);
 */        const translatedMessage = this.translate.instant(response.message);
        this.dialogRef.close([{ isSuccess: response.isSuccess, message: translatedMessage }]);
      },
      error => {
        const errorMessage = this.translate.instant('addBranchAlert');
        this.dialogRef.close([{ isSuccess: false, message: errorMessage }]);
      }
    );
  }

  deleteBranch(index: number): void {
    this.listaSucursales.splice(index, 1);
  }

  invalidField(campo: string): boolean {
    const campoValor = this.miFormulario.get(campo)?.value || '';
    if (this.listaSucursales.length === 0) {
      return (this.miFormulario.get(campo)?.invalid || campoValor.trim() === '') && this.miFormulario.get(campo)?.touched;
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private transformValue(value: string): string {
    if (value == null) {
      return '';
    }
    return value.replace(/[^A-Za-z0-9À-ÿ\u00f1\u00d1\s.\-\/()"]/g, '').trimStart();
  }

  getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
        },
        (error) => {
          console.error('Error obteniendo la ubicación: ', error);
        }
      );
    } else {
      console.error('El navegador no soporta geolocalización.');
    }
  }

  // Inicializar el mapa una vez que el script está cargado
  initMap() {
    const mapOptions: google.maps.MapOptions = {
      center: this.center,
      zoom: this.zoom,
      streetViewControl: false, // Deshabilitar el control de Street View
      mapTypeControl: false, // Deshabilitar el control de tipo de mapa (Mapa/Satélite)
      fullscreenControl: false, // Opcional: Deshabilitar el control de pantalla completa
    };

    const mapElement = document.getElementById('map') as HTMLElement;
    this.map = new google.maps.Map(mapElement, mapOptions);

    this.marker = new google.maps.Marker({
      position: this.center,
      map: this.map,
      draggable: true
    });

    this.addMyLocationControl();

    if (!this.address || this.address.trim() === '') { this.getAddress(this.center.lat, this.center.lng); }

    // Escuchar el evento de arrastrar el marcador
    this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.center = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        };
        this.marker.setPosition(this.center); // Mover el marcador a la nueva posición
        this.getAddress(this.center.lat, this.center.lng); // Obtener la dirección
      }
    });
  }

  // Función para añadir el botón de geolocalización
  addMyLocationControl() {
    // Crear el contenedor del botón
    const locationButton = document.createElement("button");
    locationButton.classList.add("custom-map-control-button");

    // Agregar el ícono HTML como innerHTML
    locationButton.innerHTML = `<mat-icon class="mat-icon notranslate mat-tooltip-trigger eye-icon w-20 h-20 material-icons"> my_location </mat-icon>`;

    // Agregar el botón de "Mi Ubicación" en la parte superior izquierda del mapa
    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(locationButton);

    // Evento para cuando se hace clic en el botón
    locationButton.addEventListener("click", () => {
      event.preventDefault();
      // Verificar si la geolocalización está disponible en el navegador
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.center = pos;

            // Centrar el mapa y mover el marcador a la ubicación del usuario
            this.map.setCenter(pos);
            this.marker.setPosition(pos);
            this.map.setZoom(15); // Zoom adecuado para mostrar la ubicación

            // Puedes también obtener la dirección si es necesario
            this.getAddress(pos.lat, pos.lng);
          },
          () => {
            alert("Error: No se pudo obtener la ubicación.");
          }
        );
      } else {
        // Si el navegador no soporta geolocalización
        alert("Error: Tu navegador no soporta la geolocalización.");
      }
    });
  }

  // Obtener la dirección a partir de las coordenadas
  getAddress(lat: number, lng: number) {
    const latlng = { lat, lng };

    this.geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          this.address = results[0].formatted_address;

          // Variables para almacenar los diferentes componentes de la dirección
          let ciudad = '';
          let estado = '';
          let pais = '';
          let countryCode = '';

          // Iterar sobre los componentes de la dirección y asignarlos según el tipo
          addressComponents.forEach(component => {
            const componentType = component.types[0];

            switch (componentType) {
              case 'locality':
                ciudad = component.long_name;
                break;
              case 'administrative_area_level_1':
                estado = component.long_name;
                break;
              case 'country':
                pais = component.long_name;
                countryCode = component.short_name;
                break;
              default:
                break;
            }
          });

          // Mostrar los componentes en los logs y actualizarlos en el formulario
          this.miFormulario.get('street')?.setValue(this.address);
          this.miFormulario.get('city')?.setValue(ciudad);
          this.miFormulario.get('state')?.setValue(estado);
          this.miFormulario.get('country')?.setValue(pais);
          this.miFormulario.get('countryCode')?.setValue(countryCode);
        } else {
          console.warn('No se encontraron resultados.');
        }
      } else {
        console.error('Geocoding falló debido a: ' + status);
      }
    });
  }

  toggleLocationVisibility() {
    this.locationVisibility = !this.locationVisibility;
  }
}
