import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { LocalService } from 'src/app/services/local.service';
import { PuzzleService } from 'src/app/services/puzzle.service';

@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.css'],

})

export class CustomDatePickerComponent implements OnInit {
  @Input() control: FormControl;
  selectedDate: Date | null = null;
  minDate: string = '1900-01-01';
  maxDate: string = '2100-12-31';
  companyId: string;
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() mobileResponsive: boolean = false;
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  @Output() datepickerStateChanged = new EventEmitter<boolean>();
  @ViewChild('datepickerContainer') datepickerContainer: ElementRef;
  @Input() isFullWidth: boolean = false;

  constructor(
    private localService: LocalService,
    private puzzleService: PuzzleService,
  ) {}

  ngOnInit(): void {
    const tokencito = this.localService.getJsonValue('token');
    if (tokencito && tokencito.data && tokencito.data.companyId) {
      this.companyId = tokencito.data.companyId;
      this.loadCompanyDetails(this.companyId);
    }
    this.setMaxDateToToday();
  }

  setMaxDateToToday(): void {
    const today = new Date();
    const day = today.getDate().toString().padStart(2, '0');
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const year = today.getFullYear();
    this.maxDate = `${year}-${month}-${day}`;
  }

  loadCompanyDetails(companyId: string): void {
    this.puzzleService.getCompanyById(companyId).subscribe(companyDetails => {
      if (companyDetails && companyDetails.comP_ADDRESS_ID_INFO.adR_CREATED) {
        const minCreatedDate = companyDetails.comP_ADDRESS_ID_INFO.adR_CREATED.toString();
        if (minCreatedDate) {
          this.minDate = minCreatedDate.split('T')[0];        }
      }
    });
  }

  toggleDatepicker() {
    this.datepicker.open();
    this.datepickerStateChanged.emit(true);
  }

  closeDatepicker() {
    this.datepickerStateChanged.emit(false);
  }

  onDateChange(event: MatDatepickerInputEvent<Date>): void {
    this.selectedDate = event.value;
  }

  onFocusOut() {
    this.datepickerStateChanged.emit(false);
  }

}
