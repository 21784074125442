import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.css']
})
export class CustomCheckboxComponent {

  @Input() isChecked: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() label: string = '';
  @Output() checkedChange = new EventEmitter<boolean>();

  onCheckboxChange() {
    if (!this.isDisabled) {
      this.isChecked = !this.isChecked;
      this.checkedChange.emit(this.isChecked);
    }
  }

}
