<div class="mat-dialog-content flex flex-col justify-center items-center h-full relative">

  <div class="p-3 absolute right-0 -top-5">
    <button id="closeModalChangeStatus2" mat-icon-button (click)="closeModal()" tabindex="-1">
      <mat-icon class="close-x-primary">close</mat-icon>
    </button>
  </div>


  <div class="text-center modal-title mb-4 m-2 md:mt-0">
    {{ 'vacancyEmailModalTitle' | translate }}
  </div>

  <div class="flex flex-col md:flex-col justify-start items-start w-full" [formGroup]="emailForm">
    <div>
      <div class="modal-subtitle mb-4">
        {{ 'vacancyEmailSutitle' | translate }}
      </div>
    </div>
    <span class="pb-1 title-input-text">{{'candidateDetailVacancy' | translate}}</span>
    <div class="custom-dropdown">
      <mat-select id="candidateDetailVacancy" formControlName="vacancySelect"
        class="borde p-2 my-1 w-full md:w-2/3 centered-text">
        <mat-option *ngFor="let vacancy of vacancy" [value]="vacancy.vacanT_ID">
          {{ vacancy.jobInfo?.joB_NAME }} - {{ vacancy.departmentInfo?.deP_NAME }} - {{
          vacancy.branchOfficeInfo?.broF_NAME || 'Sin definir' }}
        </mat-option>
      </mat-select>
      <div *ngIf="emailForm.get('vacancySelect').invalid && (emailForm.get('vacancySelect').dirty || emailForm.get('vacancySelect').touched)"
        class="text-red-500 text-xs mt-2">
        <span *ngIf="emailForm.get('vacancySelect').errors['required']">{{ 'vacancyMassiveLinkRequired' | translate }}</span>
      </div>
    </div>
    <div class="modal-subtitle mt-4 mb-4">
      {{ 'vacancyEmailInstructions' | translate }}
    </div>
    <div class="w-full">
      <app-custom-input id="vacancyEmailInstructions" placeholder="ejemplo@correo.com" formControlName="email"
        (keydown.enter)="addEmail($event)" width="100%" height="48px" class="w-full"
        [maxlength]="100"></app-custom-input>
      <div *ngIf="emailForm.get('email').invalid && (emailForm.get('email').dirty || emailForm.get('email').touched)"
        class="text-red-500 text-xs mt-2">
        <span *ngIf="emailForm.get('email').errors['required']">{{ 'emailMassiveLinkRequired' | translate }}&nbsp;</span>
        <span *ngIf="emailForm.get('email').errors['email'] || emailForm.get('email').errors['isEmailValid']">{{ 'emailMassiveLinkValid' | translate }}</span>
      </div>
      <div class="flex overflow-x-auto max-w-full">
        <ul class="flex space-x-4 py-2">
          <li *ngFor="let email of emailList; let i = index"
            class="bg-gray-200 rounded-full px-4 py-1 flex items-center space-x-2">
            <span>{{ email }}</span>
            <button id="deleteEmailVacancy" (click)="deleteEmail(i)" class="text-red-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="ml-auto mr-auto p-0  w-full md:w-5/12 mt-6">
      <app-custom-primary-button id="vacancyModalSend" size="auto" color="primary"
        (click)="sendEmails()">{{'vacancyModalSend' | translate}}</app-custom-primary-button>
    </div>
  </div>

  <div *ngIf="showErrorMessage" class="error-modal">
    <div class="error-content">
      <img [src]="dangerousIcon" alt="Icono de error" class="error-icon ml-3">
      <div class="flex flex-col items-start">
        <div class="additional-text pl-4 pt-2 pb-1">Error</div>
        <div class="error-message pl-4 pb-2">
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <mat-icon id="closeErrorModalVacancyEmail" class="close-button cursor-pointer mr-2 mb-4"
      (click)="closeErrorModal()">close</mat-icon>
  </div>

</div>