import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadedImage } from 'ngx-image-cropper';
import { ResponseDialogComponent } from '../response-dialog/response-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.css']
})
export class ImageCropDialogComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageBase64: any = '';
  imageRatio: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ImageCropDialogComponent>,
  ) {
    this.imageChangedEvent = data.event;
    if (data.event.target.files[0].type === 'image/svg+xml') {
      this.changeFormatToBase64(data.event.target.files[0]);
    }

    if (data.ratio) {
      this.imageRatio = data.ratio;
    } else {
      this.imageRatio = 1/1;
    }
  }

  ngOnInit(): void {
  }

  sendCroppedImage() {
    this.dialogRef.close(this.croppedImage);
  }

  cancel() {
    this.dialogRef.close();
  }

  imageCropped(event: any) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // Cropper listo
  }
  loadImageFailed() {
    // show message
    this.openResponseDialog(0, this.translate.instant('imgCropperError'), this.translate.instant('imgCropperErrorMessage'));
    this.dialogRef.close();
  }

  changeFormatToBase64(file: File): void {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        this.imageBase64 = canvas.toDataURL('image/png'); // Convert to PNG base64
        this.imageChangedEvent = {
          target: {
            files: [this.dataURLToFile(this.imageBase64, file.name)]
          }
        };
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  }

  // Helper function to convert dataURL to File object
  dataURLToFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  closeModal() {
    this.dialogRef.close();
  }

  openResponseDialog(mode: number, title?: string, message?: string) {
    let data: string[] = [];
    switch (mode) {
      case 0: {
        data.push('Error');
        data.push(title);
        if (message) {
          data.push(message);
        }
        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 1: {
        data.push('Exito');
        data.push(title);
        if (message) {
          data.push(message);
        }

        const dialogRef = this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
      case 2: {
        data.push('loading');
        data.push(title);
        if (message) {
          data.push(message);
        }

        return this.dialog.open(ResponseDialogComponent, {
          width: '500px',
          data: data
        });
      } break;
    }
  }
}
