import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-email-dropdown',
  templateUrl: './email-dropdown.component.html',
  styleUrls: ['./email-dropdown.component.css']
})
export class EmailDropdownComponent implements OnInit {

  @Input() email: string;
  @Input() hover: boolean = false;
  @Input() tooltip: string = null;
  outlook: SafeResourceUrl = '../../../assets/icons/outlook.svg';
  gmail: SafeResourceUrl = '../../assets/icons/gmail.svg';
  meet: SafeResourceUrl = '../../assets/icons/google-meet.svg';
  teams: SafeResourceUrl = '../../assets/icons/teams.svg';
  zoom: SafeResourceUrl = '../../assets/icons/zoom.svg';
  skype: SafeResourceUrl = '../../assets/icons/skype.svg';
  @ViewChild('secondMenuTrigger', { read: MatMenuTrigger }) secondMenuTrigger: MatMenuTrigger;

  openSecondMenu(event: Event, trigger: MatMenuTrigger) {
    event.stopPropagation();
    trigger.openMenu();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
