<div *ngIf="open" class="absolute bottom-full card p-2">
    <div class="absolute -right-1 -top-1 btn-close">
        <button mat-icon-button (click)="close()" tabindex="-1">
            <mat-icon class="close-x-primary">close</mat-icon>
        </button>
    </div>
    <ng-container *ngIf="template">
        <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
</div>
