import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Candidate } from "src/app/interfaces/Candidate";
import { LocalService } from "src/app/services/local.service";
import { PuzzleService } from "src/app/services/puzzle.service";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-docu-viewer",
  templateUrl: "./docu-viewer.component.html",
  styleUrls: ["./docu-viewer.component.css"],
})
export class DocuViewerComponent implements OnInit {
  pdfUrl: string;

  constructor(
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private puzzleService: PuzzleService,
    private localService: LocalService,
    private dialogRef: MatDialogRef<DocuViewerComponent>,
    @Inject(MAT_DIALOG_DATA) { data, header, title }
  ) {
    this.data = data;
    if (header != null) {
      this.documentHeader = header;
    }
    if (title != null) {
      this.title = title;
    }

    let re = /(?:\.([^.]+))?$/;

    if (data.cv != null) {
      this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE
      );

      let exten = re.exec(this.data.cv.cdoC_DOCUMENT_ROUTE)[1];

      if (exten != null) {
        if (
          exten.toLowerCase().includes("gif") ||
          exten.toLowerCase().includes("jpg") ||
          exten.toLowerCase().includes("png") ||
          exten.toLowerCase().includes("webp") ||
          exten.toLowerCase().includes("jpeg")
        ) {
          this.imgsource = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE
          );
        } else if (exten.toLowerCase().includes("pdf")) {
          this.pdfUrl = environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE;
        } else {
          const url = environment.imageUrl + this.data.cv.cdoC_DOCUMENT_ROUTE;
          window.open(url, "_blank");
          this.dialogRef.close();
        }
      }
    } else {
      this.pdfsource = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.imageUrl + this.data
      );

      let exten = re.exec(this.data + "")[1];
      if (exten != null) {
        const paths = ['webapi.haibuhr.com/Storage', 'webapi-devel.haibuhr.com/Storage'];

        // Remove 'https://' or 'http://' from the URL
        const normalizedUrl = this.data.toString().replace(/^https?:\/\//, '');

        const isMatch = paths.some(path => normalizedUrl.startsWith(path));
        if (
          exten.toLowerCase().includes("gif") ||
          exten.toLowerCase().includes("jpg") ||
          exten.toLowerCase().includes("png") ||
          exten.toLowerCase().includes("webp") ||
          exten.toLowerCase().includes("jpeg")
        ) {
          if (isMatch) {
            this.imgsource = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.data.toString()
            );
          } else {
            this.imgsource = this.sanitizer.bypassSecurityTrustResourceUrl(
              environment.imageUrl + this.data
            );
          }
        } else if (exten.toLowerCase().includes("pdf")) {
          if (isMatch) {
            this.pdfUrl = this.data.toString();
          } else {
            this.pdfUrl = environment.imageUrl + this.data;
          }
        } else {
          let url = '';
          if (isMatch) {
            url = environment.imageUrl + this.data;
          } else {
            url = this.data.toString();
          }
          window.open(url, "_blank");
          this.dialogRef.close();
        }
      }
    }
  }

  documentHeader: string = "Curriculum vitáe";
  title: string;
  data: Candidate;
  pdfsource: SafeResourceUrl;
  imgsource: SafeResourceUrl;

  ngOnInit(): void { }
}
